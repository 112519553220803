import dsf from "../utils";

/*
 * @Descripttion: 图表mixins
 * @Author: zhanghang
 * @Date: 2021-07-27 14:01:59
 * @LastEditors: zhanghang
 * @LastEditTime: 2022-03-30 14:40:05
 */
export default {
  props: {
    slots: {
      type: Array,
      default() {
        return [{ name: "default", controls: [] }];
      }
    },
    //数据源default:[satatic][interface]
    dataType: {
      type: String,
      default: "static",
    },
    //动态数据路径
    dataURL: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "300px",
    }
  },
  watch: {
    width() {
      this.$nextTick(() => {
        this.chartObj && this.chartObj.resize();
      });
    },
    height: {
      handler(v) {
        if (this.isDesign) {
          let height = v;
          if (!v) {
            height = "auto";
          }
          if (dsf.isNumber(height)) {
            height += "px";
          }
          this.$dispatch("design-height-change", height);
        }
        this.$nextTick(() => {
          this.chartObj && this.chartObj.resize();
        });
      },
      immediate: true
    },
    //数据来源类型
    dataType() {
      this.$nextTick(() => {
        this.init(true);
      });
    },
    //动态数据地址
    dataURL() {
      this.$nextTick(() => {
        this.init();
      });
    },
    //图表配置项
    chart: {
      handler(val) {
        this.$nextTick(() => {
          this.reset()
        });
      },
      deep: true,
    },
  },
  data() {
    return {
      // 图表对象
      chartObj: null
    };
  },
  mounted() {
    this.loadCharts(this).then(() => {
      this.init();
    }).catch((ex) => {
      dsf.error(ex)
    })
  },
  beforeDestroy() {
    this.chartObj && this.chartObj.dispose();
    // window.removeEventListener("reisize", this.$resize.bind(this));
  },
  methods: {
    loadCharts() {
      return dsf.chart.loadEcharts(this).then(() => {
        let echarts = window.$echarts || window.echarts
        this.chartObj = echarts.init(this.$refs["chart"]);
      }).catch((ex) => {
        throw ex;
      });
    },
    init(flag) {
      this.chartObj.showLoading({
        text: '正在加载数据'
      });
      if (flag) {
        this.chart = _.cloneDeep(this.cloneDefault);
        this.reset()
        return false;
      }
      // 接口形式
      if (this.dataType === "interface" && !this.isDesign) {
        //对数据进行拦截
        if (!this.dataURL) {
          this.$dispatch('interceptData', { options: this.chart, vm: this })
        } else {
          this.$http
            .get(this.$replace(this.dataURL, this.local))
            .done(({ success, data }) => {
              if (success) {
                return Promise.resolve(data);
                // if (this.$attrs["intercept"] && typeof this.$attrs["intercept"] === "function") {
                //   return this.$attrs["intercept"].call(this, data, this.chart);
                // } else {
                //   return Promise.resolve(data);
                // }
              }
            })
            .then((data) => {
              if (data) {
                this.analysis(data);
              }
            })
            .error((err) => {
              this.$message((err && err.message) || "请求异常", false);
            })
        }
        // 数据源
      } else if (this.dataType === "datasource" && !this.isDesign) {
        if (this.dataURL) {
          this.$http
            .get(this.$replace(this.dataURL, this.local))
            .done(({ success, data }) => {
              if (success) {
                //数据源分析
                this.dataSourceAnalysis(data)
              }
            })
            .error((err) => {
              this.$message((err && err.message) || "请求异常", false);
            })
        } else {
          this.$message("数据源地址未配置", false);
        }

      } else {
        this.reset()
      }
    },
    //重新请求数据，渲染
    resetByHttp(url, params) {
      return this.$http
        .get(url, params)
        .done(({ success, data }) => {
          if (success) {
            if (this.dataType === "datasource") {
              //数据源分析
              this.dataSourceAnalysis(data)
            } else {
              return Promise.resolve(data);
            }

          }
        })
        .then((data) => {
          if (data) {
            return this.analysis(data);
          }
        })
        .error((err) => {
          this.$message((err && err.message) || "请求异常", false);
        })
    },
    reset() {
      this.chartObj.hideLoading();
      this.chartObj && this.chartObj.setOption(this.chart, true);
    },
    $resize() {
      setTimeout(() => {
        this.chartObj && this.chartObj.resize();
      }, 0);
    },
    resize() {
      setTimeout(() => {
        this.$resize();
      }, 500);
    },
  }
}