import formControl from './formControl';

export default {
  props: {
    // 值
    value: {
      type: [Object, String, Number, Array],
      required: false,
    },
    // 选项
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    // 是否有其他字段
    isOther: {
      type: Boolean,
      default: false
    },
    // 只读格式化
    formatter: {
      type: String,
      default: "textValue^text",
    },
    // 值字段
    valueField: {
      type: String,
      default: 'value'
    },
    // 文本字段
    textField: {
      type: String,
      default: 'text'
    },
    // 别名字段
    aliasField: {
      type: String,
      default: 'alias'
    },
    // 子级字段
    childrenField: {
      type: String,
      default: 'children'
    },
    // 文本框字段
    attachField: {
      type: String,
      default: 'attach'
    },
    // 选项中显示的文本字段
    optionsTextField: {
      type: String,
      default: 'text'
    },
    //选中时显示的值
    selectedTextField: {
      type: String,
      default: 'text'
    },
    // 提交数据是否包含别名
    postHasAlias: {
      type: Boolean,
      default: false
    },
    // 需要绑定文本框的选项的value
    needInputOptions: {
      type: String,
      default: ''
    },
    // 文本框宽度
    attachWidth: {
      type: String,
      default: '200px'
    },
    // 元数据
    metadata: {
      type: Object,
      default() {
        let md = dsf.metadata.get("items-meta-data");
        return md;
      },
    },
  },
  data() {
    return {
      valueCopy: [],
      // 要绑定文本框的选项
      attachOptions: {}
    };
  },
  computed: {
    selectValue: {
      get() {
        if (this.multiple) {
          if (dsf.isArray(this.valueCopy)) {
            return this.valueCopy.map((v) => v[this.valueField]);
          }
          return [];
        } else {
          if (dsf.isArray(this.valueCopy) && this.valueCopy.length) {
            return this.valueCopy[0][this.valueField];
          }
          return "";
        }
      },
      set(v) {
        this.initValue(v);
      },
    },
    realItems() {
      let res = _.clone(this.items);
      if (this.isDesign && !res.length) {
        res = [
          { [this.textField]: '数据1', [this.valueField]: '1' },
          { [this.textField]: '数据2', [this.valueField]: '2' },
        ];
      }
      if (this.$isOther) res.push({
        [this.textField]: '其他',
        [this.valueField]: '$else'
      })
      return res;
    },
    // 判断是否要单独新增“其他”选项
    $isOther() {
      return this.isOther && this.items.findIndex((v) => v.value === "$else") === -1;
    },
    // 要绑定文本框的选项的value值
    attachItemValues() {
      return Object.keys(this.attachOptions);
    },
  },
  watch: {
    value: {
      handler(to) {
        this.initValue(to);
      },
      immediate: true,
    },
    realItems() {
      this.initValue(this.value);
    },
    'metadata.dataSource': {
      handler(to) {
        let values = [];
        if (this?.metadata?.dataSource) {
          _.each(this.metadata.dataSource.attachFullMaps || [], (it) => {
            if (this.metadata.dataSource.type == 'db' || this.metadata.dataSource.type == 'dict') {
              if (this.metadata.dataSource.code == it.rmId) {
                values.push(it.value)
              }
            }
            else {
              values.push(it.value)
            }
          })
        }
        this.$emit('update:needInputOptions', values.join(","))
      },
      deep: true
    },
    needInputOptions: {
      handler(to) {
        let vs = to.trim();
        if (!vs) {
          this.attachOptions = {};
          return;
        }
        let res = {}
        _.forEach(vs.split(","), v => {
          res[v] = '';
        });
        this.attachOptions = res;
      },
      immediate: true,
    }
  },
  methods: {
    selectChange() {
      this.$nextTick(() => {
        this.$dispatch("change", this.value);
        this.emitFormValidate(this.value);
      });
    },
    setAttach(field, value) {
      this.$set(this.attachOptions, field, value);
      if (dsf.isArray(this.value)) {
        let index = _.findIndex(this.value, v => v.value === field);
        this.$set(this.value[index], 'attach', value);
        this.valueCopy[index].attach = value;
      } else if (this.value?.value === field) {
        this.$set(this.value, 'attach', value);
        this.valueCopy.attach = value;
      }
    },
    initValue(to) {
      if (dsf.isObject(to) && to.text === '' && to.value === '' && !to.alias) to = null;
      let nv;
      if (to === void 0 || to === null) {
        nv = [];
      } else if (dsf.type(to) === "array") {
        nv = this.filterValue(to);
      } else {
        nv = this.filterValue([to]);
      }
      if (!this.isSame(nv, this.valueCopy)) {
        this.valueCopy = nv;
        this.updateValue(_.cloneDeep(nv));
      }
    },
    filterValue(value) {
      let newValue = [];
      _.forEach(value, v => {
        let _v = void 0;
        if (dsf.isObject(v) && v.value !== void 0) {
          if (v.attach) {
            this.$set(this.attachOptions, v.value, v.attach);
          }
          _v = this.getObjectByValue(v.value);
        } else if (dsf.isString(v) || dsf.isNumber(v)) {
          _v = this.getObjectByValue(v);
        }
        if (_v) {
          newValue.push(_v);
        }
      });
      return newValue;
    },
    getObjectByValue(v) {
      let res = null;
      dsf.deepForEach(this.realItems, (item) => {
        let str1 = dsf.isDef(item.value) ? item.value.toString() : ""
        let str2 = dsf.isDef(v) ? v.toString() : ""
        if (str1 === str2) {
          res = item;
          return -1;
        }
      });
      return res;
    },
    // 判断两个值是否相同
    isSame(p1, p2) {
      if (dsf.type(p1) === "array" && dsf.type(p2) === "array") {
        if (p1.length !== p2.length) return false;
        for (let i = 0; i < p1.length; i++) {
          let same = false;
          for (let j = 0; j < p2.length; j++) {
            let c1 = p1[i][this.textField] === p2[j][this.textField],
              c2 = p1[i][this.valueField] === p2[j][this.valueField],
              c3 = !this.postHasAlias || p1[i][this.aliasField] === p2[j][this.aliasField],
              c4 = p1[i][this.attachField] === p2[j][this.attachField];
            if (c1 && c2 && c3 && c4) {
              same = true;
              break;
            }
          }
          if (!same) {
            return false;
          }
        }
        return true;
      }
      return p1 === p2;
    },
    updateValue(to) {
      let realValue = _.map(to, t => {
        let res = {}
        for (let k in t) {
          if (k == 'text' || k == 'value' || k == 'alias') {
            res[k] = t[this[k + "Field"]];
          }
          else if (k !== 'children') {
            res[k] = t[k];
          }
        }
        if (this.attachItemValues.length) {
          res.attach = this.attachOptions[res.value] || '';
        }
        return res;
      });
      let item = { text: "", value: "" };
      if (this.postHasAlias) {
        item.alias = "";
      }
      if (this.multiple) {
        if (realValue.length) {
          item = realValue;
        }
        this.emitValueChange(realValue);
      } else {
        if (realValue.length) {
          item = realValue[0];
        }
        this.emitValueChange(item);
      }
    },
  },
  design: {
    layout: {
      //#开头表示该属性在预览模式下会被保留
      exclude: ["value", "#items"]
    },
    settingMenus: [
      ...(formControl.design.settingMenus || []),
      {
        text: "选项设置",
        command: "datasource",
        handler() {
          dsf.designer.triggerDialogPropty("metadata.dataSource");
        },
      }
    ],
    metadata: {
      create(root) {
        let res = formControl.design.metadata.create.call(this, root);
        // 处理别名alias
        let aliasIndex = _.findIndex(res.metadata.valueAttributes, it => it.code === 'alias');
        if (this.postHasAlias && aliasIndex === -1) {
          res.metadata.valueAttributes.push({
            name: "别名",
            code: "alias",
            type: dsf.metadata.getDataType("string"),
            length: 100,
            defaultValue: null,
            encrypt: false,
            unit: null,
          })
        } else if (!this.postHasAlias && aliasIndex > -1) {
          res.metadata.valueAttributes.splice(aliasIndex, 1);
        }

        // 处理文本框attach
        let attachIndex = _.findIndex(res.metadata.valueAttributes, it => it.code === 'attach');
        if (this.attachItemValues.length && attachIndex === -1) {
          res.metadata.valueAttributes.push({
            name: "附加填写文本",
            code: "attach",
            type: dsf.metadata.getDataType("string"),
            length: 100,
            defaultValue: null,
            encrypt: false,
            unit: null,
          })
        } else if (!this.attachItemValues.length && attachIndex > -1) {
          res.metadata.valueAttributes.splice(attachIndex, 1);
        }
        res.isTextValueEnum = true;
        return res;
      }
    }
  },
}