// import store from "../store";

//设置路由过渡效果
let localSessionRouteChain = sessionStorage.getItem('$$routeChain') ? JSON.parse(sessionStorage.getItem('$$routeChain')) : []
let state = {
  addCount: localSessionRouteChain.length,
  routerMap: {},
  pageDirection: 'slide-left',
  routeChain: localSessionRouteChain,
}
window.$$state = state;

function addRouteChain(route) {
  if ((state.addCount !== 0 && state.routeChain[state.routeChain.length - 1].path !== route.path) || state.addCount === 0) {
    state.routeChain.push({
      name: route.name,
      path: route.path,
      keepAlive: route.meta.keepAlive
    })
    sessionStorage.setItem('$$routeChain', JSON.stringify(state.routeChain))
    state.addCount++
  }
}

function popRouteChain(index) {
  if (dsf.isDef(index)) {
    state.routeChain = state.routeChain.slice(0, index + 1);
  } else {
    state.routeChain.pop();
  }
  sessionStorage.setItem('$$routeChain', JSON.stringify(state.routeChain))
}

function setPageDirection({ dir, to, from }) {
  state.pageDirection = dir
  state.routerMap['to'] = to.fullPath
  state.routerMap['from'] = from.fullPath
}

function setRouterMap() {
  let dir = state.pageDirection
  let to = state.routerMap.to.replace(/\//g, '_')
  let from = state.routerMap.from.replace(/\//g, '_')
  try {
    if (dir === 'slide-left') {
      // 进入
      state.routerMap[from] = document.getElementById(from).scrollTop
    } else if (dir === 'slide-right') {
      // 返回
      if (this.keepAlive === true && this.$route.meta.keepAlive !== false) {
        document.getElementById(to).scrollTop = state.routerMap[to]
      }
    } else {
      //
    }
  } catch (error) {
    // throw error;
  }
}

function clearAll(store) {
  state.routerMap = {};
  state.routeChain.splice(0, state.routeChain.length);
  sessionStorage.setItem('$$routeChain', JSON.stringify(state.routeChain))
  state.addCount = state.routeChain.length;
  // state.topsViews = [];
  store.commit("keepAlive/clear")
}
function clearCache(store, to, from, isTop) {
  let state = window.$$state;
  let routeChain = state.routeChain;
  //如果页面为底部导航中的一级页面则不被清理缓存强制keepAlive
  if (isTop) {
    return;
  }
  let to_index = _.findIndex(routeChain, r => r.path == to.path);
  let from_index = _.findIndex(routeChain, r => r.path == from.path);
  //formIndex>to_index时表示回退,此时需要清除keepalive中的弱缓存
  if ((to_index >= 0 && from_index >= 0) && from_index > to_index) {
    for (let i = from_index; i > to_index; i--) {
      if (!routeChain[i].keepAlive) {
        // debugger
        store.commit("keepAlive/remove", routeChain[i].path);
      }
    }
  }
}

function addCache(store, to, form, vm) {
  // debugger
  store.commit("keepAlive/add", {
    name: to.name,
    path: to.path,
    vm: vm
  });
}


function initRouteTransition(router) {
  router.afterEach((to, from) => {
    // 定义一个可以记录路由路径变化的数据，这里用sessionStorage,或者在window.routeChain等变量
    let routeLength = state.routeChain.length;
    if (routeLength === 0 || state.addCount === 0) {
      setPageDirection({ dir: 'slide-left', to, from })
      addRouteChain(from)
      addRouteChain(to)
    } else if (routeLength === 1) {
      setPageDirection({ dir: 'slide-left', to, from })
      addRouteChain(to)
    } else {
      // let lastBeforeRoute = state.routeChain[routeLength - 2];
      let beforePaths = state.routeChain.slice(0, routeLength - 1);
      let beforePathIndex = _.findLastIndex(beforePaths, (el) => el.path == to.path);
      let lastBeforeRoute = null;
      if (beforePathIndex >= 0) {
        lastBeforeRoute = beforePaths[beforePathIndex];
      }
      if (lastBeforeRoute && lastBeforeRoute.path === to.path && to.meta.slideLeft !== true) {
        popRouteChain(beforePathIndex);
        setPageDirection({ dir: 'slide-right', to, from })
      } else {
        addRouteChain(to)
        setPageDirection({ dir: 'slide-left', to, from })
      }
    }

  });
}


export default {
  addRouteChain,
  popRouteChain,
  setPageDirection,
  initRouteTransition,
  addCache,
  clearCache,
  // setInstance,
  clearAll
}