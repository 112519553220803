//创建动态路由

import pcLayoutViewMixins from "../mixins/pcLayoutView";
import mobileLayoutViewMixins from "../mixins/mobileLayoutView";
import enums from '../utils/enums'
let __componetMap = {
  pc: {},
  mobile: {}
};
const NOT_FOUND_EXTJS = "EXTJS未能找到";

function setMap(client, name, options) {
  __componetMap[client][name] = options;
}

function getMap(client, name) {
  return __componetMap[client][name];
}
export default function (namespace, client, isRouter) {
  // let cname = getComponentName(namespace);
  client = client || "pc";
  return getRemoteComponent(namespace, isRouter);

  function getComponentName(ns) {
    return client + "-" + ns.replace(/\./g, "-");
  }

  //动态创建组件
  function getRemoteComponent(ns, isRouter) {
    return new Promise((resolve, reject) => {
      //加载组件的js、css文件
      // let _this = this;
      if (!getMap(client, ns)) {
        getComponentTemplate(ns)
          .then((response) => {
            if (!getMap(client, ns)) {
              let templateStr = response.context.tpl;
              if (client == 'mobile') {
                templateStr = "<div>" + templateStr + "</div>"
              }
              // templateStr = "<div>"+templateStr+"</div>"
              // let compileFn =  dsf.global.Vue.compile(templateStr);
              let compileFn = Vue.compile(templateStr);
              let moduleName = getModuleName(ns);
              let serverCreateHtmlDir = dsf.config.serverCreateHtmlDir;
              dsf.require({
                baseUrl: serverCreateHtmlDir,
                urlArgs: (!dsf.config.setting_public_layoutviewcache ? '_t=' + (new Date()).getTime() : null)
              }, [moduleName], (ext) => {
                ext.mode = response?.context?.layout?.mode || enums.viewMode.DEVELOP;
                ext.pageType = response?.context?.layout?.pageType;
                ext.pageTitle = response.context.layout.title;
                ext.name = _.camelCase(getComponentName(ns));
                ext.needLogin = response.context.layout.needLogin === false ? false : true;
                ext.pageLevel = response.context.layout.pageLevel || 1;
                ext.isKeepAlive = response.context.layout.isKeepAlive || false;
                let mixin = client == "mobile" ? mobileLayoutViewMixins : pcLayoutViewMixins;
                ext.extends = mixin;
                ext.mixins = [];
                ext.waterMark = response.context.layout.waterMark === false ? false : true;
                ext.$$service = dsf.mix({}, response?.context?.service || {});
                ext.$$controls=response?.context?.props();
                ext.metadataDict = response?.context?.metadataDict ? dsf.mix(true, {}, response?.context?.metadataDict || {}) : null;
                ext.$$pageDesignId=response?.context?.layout?.designId || "";
                let staticData = dsf.mix(true, {}, response?.context?.staticValue);
                let pt = dsf.config.layoutViewMixins[response.context.layout.pageType];
                //如果是其他特殊类型表单，例如问卷
                _.each(pt || [], (it) => {
                  //读取页面是否有其他混入配置
                  if (window.$mixins[it]) {
                    ext.mixins.push(window.$mixins[it]);
                  }
                });
                // let staticData = response?.context?.staticValue
                ext.mixins.push({
                  render: compileFn.render,
                  data() {
                    let staticData = staticData
                    return {
                      $$staticValue: staticData || {},
                      $$service: ext.$$service || {},
                      $$controls: dsf.mix(true,{},ext.$$controls),
                      metadataDict: dsf.mix(true, {}, ext.metadataDict),
                      $$keepAlive: ext?.isKeepAlive,
                      $$pageType: ext?.pageType,
                      $$mode: ext?.mode,
                      $$pageDesignId: ext.$$pageDesignId || "",
                    };
                  },
                  computed: {
                    $controls() {
                      return this._data.$$controls;
                    },
                    $controlsMapping() {
                      let mapping = {};
                      for (let k in this._data.$$controls) {
                        let props = this._data.$$controls[k];
                        let hash = k.split("-")[1];
                        hash = (hash ? "-" + hash : "");
                        let key = props.caption + hash;
                        if (props.isFormControl) {
                          key = props.metadata_fullcode + hash;
                        }
                        mapping[key] = k;
                      }
                      return mapping;
                    },
                    $baseUrl: {
                      get() {
                        // return "/"
                        return this?._data?.$$service?.baseUrl || "";
                      },
                      set(v) {
                        this._data.$$service.baseUrl = v;
                      }
                    },
                    $pageComponentProps() {
                      return this._data.$$controls[this._data.$$pageDesignId];
                    }
                  },
                  created() {
                    let pageProps = this._data.$$controls[this._data.$$pageDesignId];
                    if (dsf.isObject(this.pageProps)) {
                      dsf.mix(pageProps, this.pageProps)
                    }
                  },
                  // methods:{
                  //   $addToControls(ctrls){
                  //     for(let k in ctrls){
                  //       ctrls[k]
                  //     }
                  //   },
                  // }
                });
                setMap(client, ns, ext);
                dsf.global.Vue.component(ext.name, ext);
                resolve(ext);
                response=null;
              }, () => {
                let msg = { message: NOT_FOUND_EXTJS };
                dsf.layer.message(msg, false);
                reject(msg);
              });
            } else {
              resolve(getMap(client, ns));
            }
          })
          .catch((ex) => {
            reject(ex);
          });
      } else {
        resolve(getMap(client, ns));
      }
    });
  }

  //获取
  function getComponentTemplate(ns) {
    return new Promise((resolve, reject) => {
      // let viewinfo = splitNameSpace(ns)
      // let cacheKey = viewinfo.path + "/" + viewinfo.file;
      getLayout(ns)
        .then((response) => {
          let layoutAndStyle = getLayoutAndStyleData(response ? response.data : {});
          let props = layoutAndStyle.layout;
          let css = layoutAndStyle.css;
          props["module-name"] = ns;
          let styleEl = `<style>${css}</style>`;
          props["customCss"] = styleEl;
          let tplAndProps = dsf.designer.convertTemplateAndControlData(props);
          let code = [
            "var obj=" + JSON.stringify(tplAndProps.props) + ";",
            "return obj"
          ];
          let propsFn = new Function(code.join("\n"));
          let result = {
            context: {
              tpl: tplAndProps.tpl,
              props: propsFn,
              layout: props,
              moduleName: ns,
              metadataDict: layoutAndStyle.metadataDict || {},
              service: layoutAndStyle.service || {},
              staticValue: layoutAndStyle.staticValue || {}
            }
          };
          resolve(result);
        })
        .catch((ex) => {
          if (dsf.type(ex) == "error") {
            let r_notfound = /40[34]+|50.+/g;
            if (r_notfound.test(ex.message)) {
              reject();
            }
          } else {
            reject(ex);
          }
        });
    });
  }

  //获取页面布局tree结构数据
  function getLayout(ns) {
    let viewinfo = splitNameSpace(ns);
    let serverCreateHtmlDir = dsf.config.serverCreateHtmlDir;
    let url = serverCreateHtmlDir + client + "/views/" + viewinfo.ns + "/" + viewinfo.file + ".layout.html";
    return dsf.http.get(url, null,
      {
        cache: dsf.config.setting_public_layoutviewcache,
        responseType: "text"
      }
    );
  }

  //获取布局数据
  function getLayoutAndStyleData(context) {
    let dom = document.createDocumentFragment();
    let div = document.createElement("div");
    div.innerHTML = context;
    dom.appendChild(div);
    let layoutNode = div.querySelector("script[id='layout']");
    let layoutCode = ["var layout=" + (layoutNode ? layoutNode.innerHTML : "").trim() + ";", "return layout;"];
    let cssNode = div.querySelector("script[id='css']");
    let cssCode = ["var css=" + JSON.stringify((cssNode ? cssNode.innerHTML : "").trim()) + ";", "return css;"];
    let metadataNode = div.querySelector("script[id='metadatedict']");
    let metadataNodeContent = metadataNode ? metadataNode.innerHTML.trim() : "";
    let metadataCode = ["var metadatadict=" + (metadataNodeContent ? metadataNodeContent : "{}").trim() + ";", "return metadatadict;"];
    let serviceNode = div.querySelector("script[id='service']");
    let serviceCode = ["var service=" + (serviceNode ? serviceNode.innerHTML : "{}").trim() + ";", "return service;"];
    let staticValueNode = div.querySelector("script[id='staticValue']");
    let staticValueCode = ["var staticValue=" + (staticValueNode ? staticValueNode.innerHTML.trim() : "{}").trim() + ";", "return staticValue;"];
    dom = null;
    div = null;
    let layoutFn = new Function(layoutCode.join("\n"));
    let cssFn = new Function(cssCode.join("\n"));
    let metadataFn = new Function(metadataCode.join("\n"));
    let layout = dsf.fillComponentDefaultProps(layoutFn());
    let css = cssFn();
    let metadataDict = metadataFn();
    let serviceFn = new Function(serviceCode.join("\n"));
    let service = serviceFn();
    let staticValueFn = new Function(staticValueCode.join("\n"));
    let staticValue = staticValueFn();
    layoutFn = null;
    cssFn = null;
    staticValueFn = null;
    serviceFn = null;
    layoutCode.splice(0, layoutCode.length);
    layoutCode = null;
    cssCode.splice(0, cssCode.length);
    cssCode = null;
    metadataCode.splice(0, metadataCode.length);
    metadataCode = null;
    serviceCode.splice(0, serviceCode.length);
    serviceCode = null;
    staticValueCode.splice(0, staticValueCode.length);
    staticValueCode = null;
    return {
      layout,
      css,
      metadataDict,
      service,
      staticValue
    };
  }

  function getModuleName(ns) {
    let info = splitNameSpace(ns);
    let serverCreateHtmlDir = dsf.config.serverCreateHtmlDir;
    return client + "/views/" + info.ns + "/" + info.file + ".ext";
  }

  //切割nameSpace
  function splitNameSpace(nameSpace) {
    if (nameSpace.startsWith(".")) {
      nameSpace = nameSpace.substr(1);
    }
    let arr = nameSpace.split(".");
    let fileName = arr[arr.length - 1];
    let path = arr.slice(0, arr.length).join(".");
    let ns = arr.slice(0, arr.length - 1).join(".");
    return {
      path: path,
      file: fileName,
      ns: ns
    };
  }
}
