
export default class Event {
  constructor() {
    this._listeners = {}
  }
  _getListeners(key) {
    let _key = this._getKey(key)
    if (!_key) return []
    if (!this._listeners[_key]) {
      this._listeners[_key] = []
    }
    return this._listeners[_key]
  }
  _getKey(key) {
    if (dsf.isObject(key)) {
      return key.type
    } else if (dsf.isString(key)) {
      return key
    }
    return
  }
  on(key, callback, once) {
    let listeners = this._getListeners(key)
    callback._isOnce = once
    listeners.push(callback)
  }
  remove(key, callback) {
    let listeners = this._getListeners(key)
    for (let i = 0; i < listeners.length; i++) {
      if (listeners[i] === callback) {
        listeners.splice(i, 1)
        return
      }
    }
  }
  dispatch(key, value) {
    let listeners = this._getListeners(key)
    let onceIndexs = []
    for (let i = 0; i < listeners.length; i++) {
      listeners[i](value)
      if (listeners[i]._isOnce) {
        onceIndexs.push(i)
      }
    }
    if (onceIndexs.length) {
      for (let i = 0; i < onceIndexs.length; i++) {
        listeners.splice(i, 1)
      }
    }
  }
}