import xss from 'xss';


let waterMark = null
function getWaterMark(text, options = {}) {
  if (!waterMark) {
    let fragment = document.createDocumentFragment()
    var can = document.createElement('canvas');
    // var body = document.body;
    fragment.appendChild(can);
    can.width = options.width || 180;
    can.height = options.height || 150;
    // can.style.display = 'none';
    var cans = can.getContext('2d');
    text = text || dsf.cookies.get("user_name");
    let texts = text.split(/\r|\n|\r\n/g);
    //ctx.translate(-60, 0);//移动坐标原点
    cans.rotate(-20 * Math.PI / 180);
    cans.font = "16px Microsoft YaHei";
    // cans.fillStyle = "rgb(224, 226, 226)";
    cans.fillStyle = "rgba(224, 226, 226," + (dsf.config.setting_public_watermark_text_alpha || 0.7) + ")";
    cans.textAlign = "center";
    for (let i = 0; i < texts.length; i++) {
      cans.fillText(texts[i], can.width / 2, can.height / 2 + i * 20);
    }
    waterMark = can.toDataURL("image/png");
    fragment = null;
  }
  return waterMark;
}

//xss过滤白名单
let whiteList = {
  a: ["class", "style", "target", "href", "title"],
  abbr: ["class", "style", "title"],
  address: ["style", "class"],
  area: ["style", "class", "shape", "coords", "href", "alt"],
  article: ["style", "class"],
  aside: ["style", "class"],
  audio: [
    "style",
    "class",
    "autoplay",
    "controls",
    "crossorigin",
    "loop",
    "muted",
    "preload",
    "src",
  ],
  b: ["style", "class"],
  bdi: ["style", "class", "dir"],
  bdo: ["style", "class", "dir"],
  big: ["style", "class"],
  blockquote: ["style", "class", "cite"],
  br: ["style", "class"],
  caption: ["style", "class"],
  center: ["style", "class"],
  cite: ["style", 'class'],
  code: ["style", 'class'],
  col: ["style", 'class', "align", "valign", "span", "width"],
  colgroup: ["style", 'class', "align", "valign", "span", "width"],
  dd: ["style", 'class'],
  del: ["style", 'class', "datetime"],
  details: ["style", 'class', "open"],
  div: ["style", 'class'],
  dl: ["style", 'class'],
  dt: ["style", 'class'],
  em: ["style", 'class'],
  figcaption: ["style", 'class'],
  figure: ["style", 'class'],
  font: ["style", 'class', "color", "size", "face"],
  footer: ["style", 'class'],
  h1: ["style", 'class'],
  h2: ["style", 'class'],
  h3: ["style", 'class'],
  h4: ["style", 'class'],
  h5: ["style", 'class'],
  h6: ["style", 'class'],
  header: ["style", 'class'],
  hr: ["style", 'class'],
  i: ["style", 'class'],
  img: ["style", 'class', "src", "alt", "title", "width", "height"],
  ins: ["style", 'class', "datetime"],
  li: ["style", 'class'],
  mark: ["style", 'class'],
  nav: ["style", 'class'],
  ol: ["style", 'class'],
  p: ["style", 'class'],
  pre: ["style", 'class'],
  s: ["style", 'class'],
  section: ["style", 'class'],
  small: ["style", 'class'],
  span: ["style", 'class'],
  sub: ["style", 'class'],
  summary: ["style", 'class'],
  sup: ["style", 'class'],
  strong: ["style", 'class'],
  strike: ["style", 'class'],
  table: ["style", 'class', "width", "border", "align", "valign"],
  tbody: ["style", 'class', "align", "valign"],
  td: ["style", 'class', "width", "rowspan", "colspan", "align", "valign"],
  tfoot: ["style", 'class', "align", "valign"],
  th: ["style", 'class', "width", "rowspan", "colspan", "align", "valign"],
  thead: ["style", 'class', "align", "valign"],
  tr: ["style", 'class', "rowspan", "align", "valign"],
  tt: ["style", 'class'],
  u: ["style", 'class'],
  ul: ["style", 'class'],
  video: [
    "style",
    'class',
    "autoplay",
    "controls",
    "crossorigin",
    "loop",
    "muted",
    "playsinline",
    "poster",
    "preload",
    "src",
    "height",
    "width",
  ],
};

let r_url = /^(http|https|ftp|ws|wss|\*):\/\/([\.\w\-]+|\*)(:(\d{1,5}|\*))?/g;
function filterUrl(urlStr) {
  if (urlStr) {
    if (urlStr.startsWith('javascript:')) {
      return ''
    }
    else {
      if (urlStr.startsWith("http://") || urlStr.startsWith("https://") || urlStr.startsWith("//")) {
        let currUrlInfo = dsf.url.parse(window.location.href);
        if (urlStr.startsWith("//")) {
          urlStr = currUrlInfo.protocol + urlInfo
        }
        let urlInfo = dsf.url.parse(urlStr);
        // let protocol = currUrlInfo.protocol;
        let result = _.find(dsf.config.setting_public_whitelist || [], (it) => {
          r_url.lastIndex = 0;
          let u = r_url.exec(it['setting_public_white_list.domain']);
          let protocol = u[1];
          let host = u[2];
          let port = u[4] || "";
          if (protocol != "*" && urlInfo.protocol != protocol + ":") {
            return false;
          }
          if (host != "*" && urlInfo.host != host) {
            return false;
          }
          if (port != "*" && urlInfo.port != port) {
            return false;
          }
          return true;
        });
        if (!result || result.length <= 0) {
          return ''
        }
        else {
          return urlStr;
        }
      }
      return urlStr;
    }
  }
}

function filterXSS(html, options) {
  var defaultOptions = {
    whiteList,
    onTagAttr(tag, name, value) {
      //拦截输出字符串，必须对src、href特殊判断，否则src、href的值默认会被过滤
      if (name == 'src' || name == 'href') {
        if (tag == 'iframe') {
          let url = filterUrl(value);
          if (url) {
            return `${name}=\"${value}\"`;
          }
          else {
            return `${name}`;
          }
        }
        if (value && value.trim().startsWith("javascript:")) {
          return `${name}`;
        }
        else {
          return `${name}=\"${value}\"`;
        }
      }
    }
  };
  let opts = dsf.mix({}, defaultOptions, options || {})
  return window.filterXSS(html, opts)
}

let obj = {
  getWaterMark,
  // xss: filterXSS,
  // url: filterUrl
}
Object.defineProperty(obj, 'url', {
  value: filterUrl,
  writable: false
})
Object.defineProperty(obj, 'xss', {
  value: filterXSS,
  writable: false
})

export default obj;