/**
 * 简易的Storage工具
 * @type {Storage}
 */


const sessionStorage = window.sessionStorage;
const localStorage = window.localStorage;



function createStorage(storage) {
  return {
    /**
     * @param key
     * @param value
     * @param original  是否原样保存
     */
    set(key, value, original = false) {
      if (original) {
        storage.setItem(key, value);
      } else {
        storage.setItem(key, JSON.stringify({
          __dsfStorageValue: value
        }));
      }
    },
    /**
     * @param key
     * @param defaultValue  默认值
     * @param original      是否原样返回
     */
    get(key, defaultValue = null, original = false) {
      let value = storage.getItem(key);
      if (!value) {
        return defaultValue;
      }
      if (original) {
        return value;
      }
      value = JSON.parse(value);
      if (value && Object.keys(value).indexOf('__dsfStorageValue') > -1) {
        return value.__dsfStorageValue;
      }
      return defaultValue;
    },
    remove(key) {
      storage.removeItem(key);
    },
    clear() {
      storage.clear();
    },
    /**
     * @param key
     * @param original    是否对原本的值进行判断
     * @returns {boolean}
     */
    has(key, original = false) {
      let value = storage.getItem(key);
      if (!value) {
        return false;
      }
      if (original) {
        return true;
      }
      value = JSON.parse(value);
      return !!(value && Object.keys(value).indexOf('__dsfStorageValue') > -1);
    },
    length() {
      return storage.length;
    }
  }
}


export default {
  session: createStorage(sessionStorage),
  local: createStorage(localStorage)
}