/*
 * @Descripttion: 自定义函数公式 必须es5 编写
 * @Author: zhanghang
 * @Date: 2021-09-15 15:30:19
 * @LastEditors: zhanghang
 * @LastEditTime: 2021-10-22 14:42:28
 */
import cachePool from "./cache";
//工具类
var UTILS = {
  //如果是时间对象或者时间字符串则返回时间对象，否则返回空
  isDate: function (date) {
    if (!date) {
      return ''
    } else if (date instanceof Date) {
      return !isNaN(date.getTime()) ? date : ''
    } else if (typeof date === 'string') {
      return !isNaN(new Date(date.replace(/-/g, '/'))) ? new Date(date.replace(/-/g, '/')) : ''
    }
  }
}

// 平均值
function AVG() {
  var args = Array.prototype.slice.call(arguments)
  var index = -1
  //保留几位小数 
  var fixed = -1
  for (var i = 0; i < args.length; i++) {
    if (typeof args[i] === 'boolean') {
      index = i
    }
  }
  // 说明需要保留小数
  if (index > -1) {
    fixed = args.slice(index + 1, index + 2).length ? args.slice(index + 1, index + 2)[0] : 1
    args = args.slice(0, index)
  }
  //不是数字无法进行计算
  for (var i = 0; i < args.length; i++) {
    if (isNaN(Number(args[i]))) {
      return ''
    }
  }
  var sum = 0;
  for (var i = 0; i < args.length; i++) {
    sum += Number(args[i])
  }
  var result = fixed > -1 ? (Number(sum / args.length).toFixed(fixed) * 1) : Number(sum / args.length)
  return result
}
function SUM() {
  var args = Array.prototype.slice.call(arguments)
  var arr = [];
  var sum = 0;
  _.each(args, (it) => {
    if (dsf.isArray(it)) {
      _.each(it, (num) => {
        arr.push(num);
      })
    }
    else {
      arr.push(it);
    }
  })
  _.each(arr, (num) => {
    if (dsf.isNumber(num)) {
      sum += parseFloat(num);
    }
  });
  return sum;
}
function MIN() {
  var args = Array.prototype.slice.call(arguments)
  var arr = [];
  var min = 0;
  _.each(args, (it) => {
    if (dsf.isArray(it)) {
      _.each(it, (num) => {
        arr.push(num);
      })
    }
    else {
      arr.push(it);
    }
  })
  return Math.min.apply(window, arr)
}
function MAX() {
  var args = Array.prototype.slice.call(arguments)
  var arr = [];
  _.each(args, (it) => {
    if (dsf.isArray(it)) {
      _.each(it, (num) => {
        arr.push(num);
      })
    }
    else {
      arr.push(it);
    }
  })
  return Math.max.apply(window, arr)
}
//对数
function LOG(x, y) {
  // 参数不是数字或者数字字符串
  if (isNaN(Number(x)) || isNaN(Number(y))) {
    return ''
  }
  var r = Math.log(x) / Math.log(y)
  // 解决浮点数精度问题
  return Math.abs(Math.round(r) - r) <= Math.pow(2, -51) ? Math.round(r) : r
}
// 条件个数
function COUNTIF() {
  var args = Array.prototype.slice.call(arguments)
  var func = args.pop()
  var sum = 0
  for (var i = 0; i < args.length; i++) {
    if (/[>!=<==]/g.test(func)) {
      if (eval(args[i] + func + '')) {
        sum += 1
      }
    } else {
      if (args[i] === func) {
        sum += 1
      }
    }
  }
  return sum
}
//余数
function MOD(number, divisor) {
  return (number % divisor)
}
// 保留小数
function FIXED(float, n) {
  return (!isNaN(Number(float)) && !isNaN(Number(n))) ? float.toFixed(n) * 1 : ''
}
// 转number
function NUMBER(args) {
  if (dsf.isUnDef(args)) {
    return null;
  }
  if (dsf.isArray(args)) {
    var arr = [];
    _.each(args, (str) => {
      if (dsf.isNumber(str)) {
        arr.push(parseFloat(str));
      }
    })
    return arr;
  }
  else if (dsf.isNumber(args)) {
    return parseFloat(args)
  }
}
//浮点数精度保证
function FLOATCALCUATE(number) {
  if (!number) {
    return ''
  }
  // 精度丢失
  if (String(number).split('.')[1] && String(number).split('.')[1].length === 17 && String(Number((number + 1))).length < String(number).length) {
    // 说明是浮点数
    return isNaN(Number(String(number).split('.')[0] + '.' + String(Number((number + 1))).split('.')[1])) ? '' : Number(String(number).split('.')[0] + '.' + String(Number((number + 1))).split('.')[1])
  } else {
    return Number(number)
  }
}
//日期函数：加减天数
function DATEDELTA(d, days, flag) {
  var date = eval("(function(){return " + UTILS.isDate + " })()")(d)
  if (!date) return ''

  if (flag == 1) {
    return new Date(date.getTime() + days * 24 * 3600 * 1000)
  } else {
    return new Date(date.getTime() - days * 24 * 3600 * 1000)
  }
}
//日期函数：返回日期天数
function DAYDT(d) {
  var date = eval("(function(){return " + UTILS.isDate + " })()")(d)
  if (!date) return ''

  return date.getDate()
}
//日期函数：开始日期和结束日期之前的天数
function DA_YS(s, e) {
  var sdate = eval("(function(){return " + UTILS.isDate + " })()")(s)
  var edate = eval("(function(){return " + UTILS.isDate + " })()")(e)
  if (!sdate || !edate) return ''
  return (edate.getTime() - sdate.getTime()) / (24 * 3600 * 1000)
}
//日期函数：返回当前日期所在周的第一天或者最后一天 
//firstday 本周起始天设定 0 是'星期一' 1 是'星期天' type 为 1 是第一天 2是最后一天
function WEEKDAY(d, firstday, type) {
  var date = eval("(function(){return " + UTILS.isDate + " })()")(d)
  if (!date) return ''

  //起始天星期一
  if (firstday === 0) {
    if (type === 1) {
      return new Date(date.getTime() - (date.getDay() - 1) * 24 * 3600 * 1000)
    } else {
      return new Date(date.getTime() + (7 - date.getDay()) * 24 * 3600 * 1000)
    }
  } else {
    if (type === 1) {
      return new Date(date.getTime() - (date.getDay()) * 24 * 3600 * 1000)
    } else {
      return new Date(date.getTime() + (6 - date.getDay()) * 24 * 3600 * 1000)
    }
  }
}
//日期函数：格式化日期
function FORMAT(d, fmt) {
  var date = eval("(function(){return " + UTILS.isDate + " })()")(d)
  if (!date) return ''

  var o = {
    "M+": date.getMonth() + 1,                 //月份   
    "d+": date.getDate(),                    //日   
    "h+": date.getHours(),                   //小时   
    "m+": date.getMinutes(),                 //分   
    "s+": date.getSeconds(),                 //秒   
    "q+": Math.floor((date.getMonth() + 3) / 3), //季度   
    "S": date.getMilliseconds()             //毫秒   
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
  return fmt;
}
//日期函数：返回当前月最后一天日期
function END_OF_MONTH(d) {

  var date = eval("(function(){return " + UTILS.isDate + " })()")(d)
  if (!date) return ''

  var new_year = date.getFullYear()
  var month = date.getMonth() + 1
  var new_month = month++;//取下一个月的第一天，方便计算（最后一天不固定）         
  if (month > 12) {
    new_month -= 12;        //月份减         
    new_year++;            //年份增         
  }
  var new_date = new Date(new_year, new_month, 1); //取当年当月中的第一天         
  return (new Date(new_date.getTime() - 1000 * 60 * 60 * 24))//获取当月最后一天日期       
}
//日期函数：当前时间是否在开始结束时间内
function TIME_BELONG_HOUR(current, start, end) {
  current = eval("(function(){return " + UTILS.isDate + " })()")(current)
  start = eval("(function(){return " + UTILS.isDate + " })()")(start)
  end = eval("(function(){return " + UTILS.isDate + " })()")(end)
  if (!current || !start || !end) return ''

  return current.getTime() >= start.getTime() && current.getTime() <= end.getTime()
}
//逻辑函数：AND,所有结构都为true 才返回true
function AND() {
  var args = Array.prototype.slice.call(arguments)
  for (let i = 0; i < args.length; i++) {
    if (!args[i]) {
      return false
    }
  }
  return true
}
//逻辑函数：OR 逻辑或 一个真就返回true否则false
function OR() {
  var args = Array.prototype.slice.call(arguments)
  for (let i = 0; i < args.length; i++) {
    if (args[i]) {
      return true
    }
  }
  return false
}

//逻辑函数 返回符合条件的值
function IF(condition, success, error) {
  return condition ? success : error
}
// 文本函数 合并字符串
function CONCATENATE() {
  var args = Array.prototype.slice.call(arguments)
  var text = ''
  for (let i = 0; i < args.length; i++) {
    text += !args[i] ? '' : args[i] + ''
  }
  return text
}
// 文本函数 删除字符串空格
function DELETEWHITESPACE(str) {
  if (!str) return ''
  str = str + ''
  return str.replace(/\s+?/g, '')
}
// 文本函数 字符串长度
function LEN(str) {
  if (!str) return ''
  str = str + ''
  return str.length + ''
}
// 文本函数 转小写
function LOWER(str) {
  if (!str) return ''
  str = str + ''
  return str.toLowerCase()
}
// 文本函数 转大写
function UPPER(str) {
  if (!str) return ''
  str = str + ''
  return str.toUpperCase()
}
// 文本函数 转文本
function STRING(number) {
  return number + ''
}
// 身份证函数 校验身份证
function IDCARD_VALIDATE(idcard) {
  //身份证正则表达式(15位)
  const isIDCard1 = /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$/;
  //身份证正则表达式(18位)
  const isIDCard2 = /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
  if (!isIDCard1.test(idcard) && !isIDCard2.test(idcard)) {
    return false;
  }
  return true;
}
// 身份证函数 获取身份证出生年月
function IDCARD_BIRTH(idcard, fmt) {
  if (!idcard) return ''
  var birth = ''
  if ((idcard + '').length === 18) {
    birth = (idcard + '').slice(6, 14)
  } else if ((idcard + '').length === 15) {
    birth = '19' + (idcard + '').slice(6, 12)
  }
  if (!fmt) return birth
  var o = {
    "M+": birth.slice(4, 6),   //月份   
    "d+": birth.slice(6),     //日   
  }
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(RegExp.$1, (birth.slice(0, 4)).substr(4 - RegExp.$1.length));
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
  return fmt;
}
//身份证函数 获取性别
function IDCARD_SEX(idcard) {
  if (!idcard) return ''
  if ((idcard + '').length === 18) {
    return String(idcard).slice(16, 17) % 2 === 0 ? 2 : 1
  } else if ((idcard + '').length === 15) {
    return String(idcard).slice(14) % 2 === 0 ? 2 : 1
  }
}
//获取数组长度
function ARRAY_SIZE(arr) {
  return arr ? arr.length : 0;
}
//字符串转数组
function TO_ARRAY(str, split) {
  if (!str || str === 'undefined') return []
  return split ? str.split(split) : str.split('')
}
//数组转字符串
function ARRAY_TO_STR(arr) {
  return Array.isArray(arr) ? arr.map(v => v.toString()).join(',') : ''
}
// 内置数学函数构造器
var created = (fn) => {
  return eval(`(function ${fn} (){
    var args = Array.prototype.slice.call(arguments)
    //不是数字无法进行计算
    for(var i=0;i<args.length;i++){
      if(isNaN(args[i]*1)){
        return ''
      }
    }
    return  Math['${fn}'.toLowerCase()].apply(null,args)
  })`)
}
const customMathematical = [
  //聚合函数
  ['AVG', AVG.toString(), true],
  ['SUM', SUM.toString(), true],
  ['MAX', MAX.toString(), true],
  ['MIN', MIN.toString(), true],

  //基本函数
  ['ABS', created('ABS').toString()],
  ['MOD', MOD.toString()],

  ['POW', created('POW').toString()],
  ['FLOOR', created('FLOOR').toString()],
  ['CEIL', created('CEIL').toString()],
  ['COUNTIF', COUNTIF.toString()],
  ['ROUND', created('ROUND').toString()],
  ['LOG', LOG.toString()],
  ['SQRT', created('SQRT').toString()],
  ['FIXED', FIXED.toString()],
  ['FLOATCALCUATE', FLOATCALCUATE.toString()],
  ['NUMBER', NUMBER.toString()],
  //日期函数
  ['DATEDELTA', DATEDELTA.toString()],
  ['DAYDT', DAYDT.toString()],
  ['DA_YS', DA_YS.toString()],
  ['WEEKDAY', WEEKDAY.toString()],
  ['FORMAT', FORMAT.toString()],
  ['END_OF_MONTH', END_OF_MONTH.toString()],
  ['TIME_BELONG_HOUR', TIME_BELONG_HOUR.toString()],
  //逻辑函数
  ['AND', AND.toString()],
  ['OR', OR.toString()],
  ['IF', IF.toString()],
  //文本函数
  ['CONCATENATE', CONCATENATE.toString()],
  ['DELETEWHITESPACE', DELETEWHITESPACE.toString()],
  ['LEN', LEN.toString()],
  ['LOWER', LOWER.toString()],
  ['UPPER', UPPER.toString()],
  ['STRING', STRING.toString()],
  //身份证函数
  ['IDCARD_VALIDATE', IDCARD_VALIDATE.toString()],
  ['IDCARD_BIRTH', IDCARD_BIRTH.toString()],
  ['IDCARD_SEX', IDCARD_SEX.toString()],
  //数组函数
  ["ARRAY_SIZE", ARRAY_SIZE.toString()],
  ['TO_ARRAY', TO_ARRAY.toString()],
  ['ARRAY_TO_STR', ARRAY_TO_STR.toString()],
]


let ufuncPools = cachePool(500);
//注入的公式
function ufunc(formula) {
  // 替换表单数据
  let formulaExpression = formula.realFormula.trim();
  let result = [];
  let express = formulaExpression
  // let express = formulaExpression.replace(/\{\{((.)+?)\}\}/g, (n, s) => {
  //   debugger;
  //   let express = s ? `__vm__.${s}` : '';
  //   // 如果表单数字输入是数字转化成number,如果numer值大于最大安全数转成字符串
  //   return result
  // })
  let fn = ufuncPools.get(express);
  if (fn) {
    return fn;
  }
  result.push("with(__vm__){")
  result.push("return " + express)
  result.push("}")
  // result = `(/^\\d+?$/.test(TO_VALUE(${express}))?(Number(TO_VALUE(${express}))>Number.MAX_SAFE_INTEGER?TO_VALUE(${express}):Number(TO_VALUE(${express}))):TO_VALUE(${express}))`
  //如果是数组或者字典对象返回其value
  function TO_VALUE(val, attr) {
    if (dsf.isUnDef(val)) {
      return null
    }
    let value = !attr ? val : val[attr];
    if (dsf.isUnDef(value)) {
      return null;
    }
    if (dsf.isNumber(value)) {
      return parseFloat(value)
    }
    if (dsf.isString(value)) {
      if (value.indexOf('NaN') >= 0 || val.indexOf('Infinity') >= 0 || val.indexOf('undefined') >= 0) {
        dsf.warn("无效的值被忽略")
        return ""
      }
    }
    return value;
  }
  //注入函数公式
  let customFn = `${TO_VALUE.toString()}
    var UTILS = ${JSON.stringify(UTILS, (key, val) => {
    if (typeof val === 'function') {
      return val + '';
    }
    return val;
  })};${formula.func};`

  _.each(customMathematical, (v, w) => {
    let key = v[0];
    let value = v[1];
    let aggregate = v[2];
    ~express.indexOf(v[0]) && (customFn += v[1] + ';')
  })

  try {
    //__vm__页面全局对象
    //__targetScope__公式所有者的的数据所在作用域
    fn = new Function('__vm__', '__local__', customFn + "\r\n" + result.join("\r\n"));
    ufuncPools.put(express, fn);
    return fn;
  } catch (e) {
    throw Error(`执行公式异常:${express}----${e}`)
  }

}
//解析公式表达式
function readFormulaExpress(input) {
  let str = input.trim();
  var rlocalVar = /[~$a-zA-Z_][~$a-zA-Z0-9_\.]*(\()+/g;
  let astTree = [];
  let c = rlocalVar.exec(str);
  if (c) {
    let start = c.index + c[0].length - 1;
    if (start > 0) {
      let exp = str.substring(0, c.index);
      if (exp.trim()) {
        astTree.push({
          type: "block",
          express: exp,
          realCode: null
        });
      }

    }
    let pol = false;
    let obj = {
      type: 'function',
      pol: pol,
      name: str.substring(c.index, c.index + c[0].length - 1),
      realCode: null,
      args: []
    };
    let cus = _.find(customMathematical, (it) => {
      return it[0] == obj.name;
    });
    if (cus && cus[2]) {
      obj.pol = true;
    }
    astTree.push(obj)
    let end = start;
    let count = 0;
    let stringOpen = false;
    let stringEnd = "";
    let args = [], argsSplit = ",", argsEnd = start;
    for (let i = start; i < str.length; i++) {
      let char = str[i];
      if (char === "'" && str.charAt(i - 1) !== "\\") {
        if (!stringOpen) {
          stringOpen = true;
          stringEnd = "'";
        }
        else {
          stringOpen = false;
          stringEnd = ""
        }
      }
      else if (char === '"' && str.charAt(i - 1) != "\\") {
        if (!stringOpen) {
          stringOpen = true;
          stringEnd = '"';
        }
        else {
          stringOpen = false;
          stringEnd = ""
        }
      }
      if (!stringOpen) {
        if (char == argsSplit && count == 1) {
          if (str.substring(argsEnd + 1, i)) {
            args.push(str.substring(argsEnd + 1, i))
            argsEnd = i;
          }
        }
        if (char == "(") {
          count++;
        }
        else if (char == ")") {
          count--;
        }
      }
      if (count == 0) {
        end = i;
        if (str.substring(argsEnd + 1, i)) {
          args.push(str.substring(argsEnd + 1, i))
        }
        argsEnd = i;
        obj.express = obj.name + "(" + str.substring(start + 1, end) + ")";
        break;
      }
    }
    if (args.length) {
      let result = _.each(args, (arg) => {
        let childFun = readFormulaExpress(arg)
        if (!childFun || childFun.length == 0) {
          obj.args.push(arg);
        }
        else {
          obj.args.push(...childFun);
        }
      })
    }
    str = str.substring(end + 1);
    let birthed = readFormulaExpress(str);
    if (birthed && birthed.length > 0) {
      astTree.push(...birthed)
    }
  }
  else {
    if (input && input.trim()) {
      astTree.push({
        type: "block",
        realCode: null,
        express: input.trim(),
      })
    }
  }
  return astTree
}
//将公式语法树转成实际代码替换元数据变量
//astTree语法树对象
//isPol是否为一个聚合函数
//每次进入后的块级回调
function createFormulaCodeStr(astTree, callback, parent, isParams) {
  let code = ""
  astTree = dsf.isArray(astTree) ? astTree : [astTree];
  _.each(astTree, (astNode) => {
    if (astNode.type == 'block') {
      // code += astNode.code;
      (callback && callback(astNode, parent, isParams))
      if (dsf.isUnDef(astNode.realCode)) {
        astNode.realCode = astNode.express;
      }
      code += astNode.realCode;
    }
    else if (astNode.type == "function") {
      if (astNode.args) {
        _.each(astNode.args, (a) => {
          let arg = createFormulaCodeStr(a, callback, astNode, true);
          a.realCode = dsf.isDef(arg) ? arg : a.express;
        });
      }
      code += astNode.name + "(";
      (callback && callback(astNode, parent, isParams))
      let argsCode = _.map(astNode.args, "realCode");
      code += argsCode.join(",")
      code += ")"
    }
  })
  return code;
}
ufunc.readFormulaExpress = readFormulaExpress;
ufunc.createFormulaCodeStr = createFormulaCodeStr

//自定义数学函数公式
export default ufunc