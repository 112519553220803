import ValidateError from '../output/validateError'
export default {
  data() {
    return {
      _error: {},
      validateRules: {}
    }
  },
  computed: {
    $errors() {
      return this.$data._errors;
    }
  },
  methods: {
    $getValidateFns(keys, dict, index) {
      let arrs = [];
      for (let i = 0; i < keys.length; i++) {
        let data = this.$viewData;
        let key = keys[i];
        let meta = dict[key];
        if (!meta?.refs?.length) {
          continue;
        }
        //如果是整个子表
        if (meta.type == 'subtable') {
          let disabled = this.$getValidateIsDisabledFn(key);
          //优先处理子表本身是否有校验
          let val = dsf.isDef(data[key]) ? data[key] : null;
          if (disabled(val, data) !== true) {
            let formName = key;
            let validateFn = this.$getValidateFunction(key, val, dict, formName, data, meta);
            let fn = validateFn ? validateFn : null;// validateFn(val, data, formName, meta) : null;
            fn && arrs.push(fn);
          }
          //子表子项校验
          let list = data[key] || [];
          let subKeys = [];
          _.each(dict, (it, itKey) => {
            if (it.inSubTable == key) {
              subKeys.push(itKey)
            }
          })
          // let subKeys = _.map(sub, (it) => {
          //   return it.indexOf(key + ".") == 0
          // });
          if (dsf.isDef(index)) {
            list = [list[index]]
          }
          _.each(list, (row) => {
            _.each(subKeys, (it) => {
              if (row.$hash) {
                let formName = it + "-" + row.$hash;
                let subValue = dsf.isDef(row[it]) ? row[it] : null;
                let submeta = dict[it];
                let subkey = it;
                if (dsf.type(subValue) == 'string' && submeta.valueIsHtml) {
                  subValue = this.$getEditorConent(subValue);
                }
                if (submeta.validateRule) {
                  subkey = submeta.validateRule;
                }
                let disabled = this.$getValidateIsDisabledFn(subkey);
                if (disabled(subValue, row) !== true) {
                  let validateFn = this.$getValidateFunction(subkey, subValue, dict, formName, row, submeta);
                  let fn = validateFn ? validateFn : null;//validateFn(subValue, row, formName, submeta) : null;
                  fn && arrs.push(fn);
                }
              }
            });
          })
        }
        else {
          //具体每个字段
          let formName = key;
          //如果是子表中的字段
          if (meta.inSubTable && dsf.isDef(index)) {
            data = this.$viewData[meta.inSubTable][index];
            formName = formName + "-" + data.$hash;
          }
          let val = dsf.isDef(data[key]) ? data[key] : null;
          if (dsf.type(val) == 'string' && meta.valueIsHtml) {
            val = this.$getEditorConent(val);
          }
          if (meta.validateRule) {
            key = meta.validateRule;
          }

          let disabled = this.$getValidateIsDisabledFn(key);
          if (disabled(val, data) !== true) {
            let validateFn = this.$getValidateFunction(key, val, dict, formName, data, meta);
            let fn = validateFn ? validateFn : null;//validateFn(val, data, formName, meta) : null;
            fn && arrs.push(fn);
          }
        }
      }
      return arrs;
    },
    $getValidateIsDisabledFn(key) {
      if (this.validateRules[key]) {
        let rule = this.validateRules[key].disabled || dsf.noop;
        return rule.bind(this);
      }
      return dsf.noop;
    },
    createValidateError(message, target, other) {
      return new ValidateError(message, target, other)
    },
    //重置表单验证结果
    resetValidate() {
      var fields = this.allComponents
      this.clearErrors();
      _.each(fields, (field) => {
        field.errors = [];
      });
    },
    //将错误推送到队列中
    pushError(errors) {
      let _errors = dsf.type(errors) == 'array' ? errors : [errors];
      _errors = _.filter(_errors, (it) => dsf.isDef(it));
      let keys = _.map(_errors, "target");
      let errorObj = {};
      for (let i = 0; i < keys.length; i++) {
        let err = []
        errorObj[keys[i]] = err;
      }
      for (let k in errorObj) {
        let result = _.filter(_errors, (it) => it.target == k);
        if (result.length > 0) {
          this.$set(this.$data._errors, k, result)
        }
        // if (errorObj[k] && errorObj[k].length > 0) {
        //   this.$set(this.$data._errors, k, errorObj[k])
        // }
      }
    },
    //清空所有错误
    clearErrors() {
      this.$data._errors = {}
    },
    //清理指定组件的错误信息
    removeErrorsByTarget(target) {
      this.$delete(this.$data._errors, target);
      // dsf.array.removeWith(this.$data._errors, (it) => it.target == target);
    },
    //清理指定列(子)表行的错误
    removeErrorsByRowHash(hash) {
      for (let k in this.$data._errors) {
        if (k.endsWith(hash)) {
          this.$delete(this.$data._errors, k)
        }
      }
      // this.$delete(this.$data._errors,target);
      // dsf.array.removeWith(this.$data._errors, (it) => it.target.endsWith(hash));
    },
    //获取校验规则
    $getValidateFunction(key, value, dict, formName, data, metadata) {
      let fn = null;
      let meta = dict[key];
      //优先读取二次开发附加的validateRule
      let rule = this.validateRules[key];
      //尝试获取表单组件实例
      let ctrl = this?.$postFieldMap?.[formName] || null;
      if (rule && (dsf.isFunction(rule) || rule.handler)) {
        let userFn = ruleProxy.call(this, (rule.handler || rule), data, formName, meta);
        if (userFn) {
          // userFn = userFn.bind(this);
          fn = (function () {
            let errors = [], err = null, errs = [];
            err = (userFn && userFn.call(this, value, data, formName, metadata)) || null;
            err && errors.push(err);
            (errs && errs.length > 0) && errors.push(...errs);
            return errors;
          }).bind(this)
        }
        else {
          fn = null;
        }
      }
      // //如果组件自带验证函数(前提时组件必须在页面中没有被销毁)
      // else if (ctrl && ctrl.$formItemInnerValidate) {
      //   fn = innerValideProxy.call(this, ctrl.$formItemInnerValidate.bind(ctrl));
      // }
      //获取元数据验证格式
      else {
        let metaFn = null, innerFn = null;
        if (meta && meta.validate) {
          let mdFn = metadataValidateConfigValidator;
          metaFn = metadataValidateProxy.call(this, mdFn, data, formName, meta);
        }
        //如果组件自带验证函数(前提时组件必须在页面中没有被销毁)
        if (ctrl && ctrl.$formItemInnerValidate) {
          let ctrlFn = ctrl.$formItemInnerValidate.bind(ctrl);
          innerFn = innerValideProxy.call(this, ctrlFn, data, formName, meta);
        }
        if (metaFn || innerFn) {
          fn = (function () {
            let errors = [], err = null, errs = [];
            err = (metaFn && metaFn(value, data, formName, metadata)) || null;
            if (!err) {
              errs = (innerFn && innerFn(value, data, formName, metadata)) || [];
            }
            err && errors.push(err);
            (errs && errs.length > 0) && errors.push(...errs);
            return errors;
          }).bind(this)
        }
        else {
          fn = null;
        }
      }
      return fn || dsf.noop;
    },
    //执行校验
    async $execValidateFns(valideFns) {
      let errors = [];
      let _this = this;
      let valueResults = [];
      
      return new Promise((resolve,reject)=>{
        _.each(valideFns || [], (it) => {
          if (dsf.isFunction(it)) {
            let result = it.call(this);
            if (result && result.length) {
              valueResults.push(...result)
            }
          }
        })
        Promise.all(valueResults)
          .then((result) => {
            _.each(result, (it) => {
              let item = dsf.isArray(it) ? it : [it];
              _.each(item, (it2) => {
                if (it2 && it2 instanceof $$ValidateError) {
                  let error = {
                    'target': it2.target,
                    'info': it2.info,
                    'message': it2.message
                  }
                  errors.push(error);
                }
              })
            });
            if(!errors.length){
              resolve()
            }
            else{
              reject(errors)
            }
          }).catch((err) => {
            dsf.error(err)
          })
      })
    }
  }
}


//代理执行validateRules中的规则
//返回方法必须第一个参数为当前值，当前作用域值，表单的formName，元数据
function ruleProxy(fn, scope, formName, metadata) {
  let ruleFn = fn;
  if (ruleFn) {
    return function (value, data, formName, metadata) {
      let result = ruleFn.call(this, value, data, formName, metadata);
      if (dsf.isUnDef(result)) {
        return;
      }
      //如果函数返回一个Promise对象
      if (result.then && result.catch) {
        return Promise((resovle, reject) => {
          result.then(() => {
            resovle();
          });
          result.catch((valiResult) => {
            let message = dsf.type(valiResult) == 'string' ? valiResult : valiResult.message
            resovle(new ValidateError(message, formName, valiResult));
          });
        });
      } else {
        if (result) {
          let message = dsf.type(result) == 'string' ? result : result.message
          return new ValidateError(message, formName, result);
        }
      }
    };
  }
  return dsf.noop;
}

function innerValideProxy(fn, scope, formName, metadata) {
  let ruleFn = fn;
  if (ruleFn) {
    return function (value, data, formName, metadata) {
      let result = ruleFn(value, data, formName, metadata);
      if (result) {
        let errors = [];
        result = dsf.isArray(result) ? result : [result];
        _.each(result, (it) => {
          let message = "", target = "";
          if (dsf.isString(it)) {
            message = it.message;
            target = formName
          }
          else if (dsf.isObject(it)) {
            message = it.message;
            target = it.target || formName;
          }
          errors.push(new ValidateError(message, target, it));
        });
        return errors;
      }
    }
  }

}
function metadataValidateProxy(fn, scope, formName, metadata) {
  let ruleFn = fn;
  if (ruleFn) {
    return function (value, data, formName, metadata) {
      let result = ruleFn(value, data, formName, metadata);
      if (result) {
        let errors = [];
        result = dsf.isArray(result) ? result : [result];
        _.each(result, (it) => {
          let message = "", target = "";
          if (dsf.isString(it)) {
            message = it.message;
            target = formName
          }
          else if (dsf.isObject(it)) {
            message = it.message;
            target = it.target || formName;
          }
          errors.push(new ValidateError(message, target, it));
        });
        return errors;
      }
    }
  }
}

//元数据配置验证器
function metadataValidateConfigValidator(value, scope, formName, metadata) {
  if (metadata && metadata.validate) {
    let rules = metadata.validate._rules || metadata.validate.rules;
    if (!rules) {
      return;
    }
    let validateRules = []
    //将必填校验放到第一个,校验时优先判断是否必填
    _.eachRight(rules, (it, i) => {
      if (it.type == "required") {
        validateRules.unshift(it);
      }
      else {
        validateRules.push(it);
      }
    });

    for (let i = 0; i < validateRules.length; i++) {
      let rule = validateRules[i];
      let attr = rule.attr;
      let errorMsg = rule.errorMsg;
      let valueAttributes = metadata.valueAttributes || [];
      let v = null;
      if (valueAttributes.length == 1) {
        v = value
      } else {
        //有可能会是数组，比如下拉框多选，多选组件
        if (dsf.isArray(value)) {
          let arr = value
          v = _.map(arr, attr).join("??");
        } else {
          v = value ? value[attr] : "";
        }
      }
      //如果是必填项
      if (rule.type == "required") {
        if (dsf.validate("isNull", v) === true) {
          return new ValidateError(dsf.express.replace(errorMsg, rule), formName, {
            attributes: attr
          });
        }
      }
      else if (rule.type == 'number') {
        if (dsf.isUnDef(v) || v === '' || v === null) {
          continue;
        }
        //验证是否是一个数字
        if (dsf.validate("isNumber", v) === false) {
          return new ValidateError(dsf.express.replace(errorMsg, rule), formName, {
            attributes: attr
          });
        }
        //验证数字最大值和最小值
        if (dsf.isNumber(rule.numberMax)) {
          if (dsf.validate("isNumberOver", v, parseFloat(rule.numberMax)) === true) {
            return new ValidateError(dsf.express.replace(errorMsg, rule), formName, {
              attributes: attr
            });
          }
        }
        if (dsf.isNumber(rule.numberMin)) {
          if (dsf.validate("isNumberUnder", v, parseFloat(rule.numberMin)) === true) {
            return new ValidateError(dsf.express.replace(errorMsg, rule), formName, {
              attributes: attr
            });
          }
        }
        //验证数字精度
        if (rule.precision) {
          if (dsf.validate("precision", v, parseFloat(rule.precision)) === false) {
            return new ValidateError(dsf.express.replace(rule.errorMsg, rule), formName, {
              attributes: attr
            });
          }
        }
      }
      else if (rule.type == 'array') {
        if (dsf.isUnDef(value) || !value.length) {
          continue;
        }
        let arr = value ? (dsf.isArray(value) ? value : [value]) : []
        let min = dsf.isNumber(rule.arrayMin) ? parseFloat(rule.arrayMin) : 0;
        let max = dsf.isNumber(rule.arrayMax) ? parseFloat(rule.arrayMax) : 0;
        if (min > 0 && arr.length < min) {
          return new ValidateError(dsf.express.replace(errorMsg, rule), formName, {
            attributes: attr
          });
        }
        if (max > 0 && arr.length > max) {
          return new ValidateError(dsf.express.replace(errorMsg, rule), formName, {
            attributes: attr
          });
        }

      }
      else if (rule.type == "string") {
        if (dsf.isUnDef(v) || v === '') {
          continue;
        }
        //验证字符串是否超出长度
        if (dsf.isNumber(rule.maxLength)) {
          if (dsf.validate("maxLength", v, parseFloat(rule.maxLength)) === true) {
            return new ValidateError(dsf.express.replace(errorMsg, rule), formName, {
              attributes: attr
            });
          }
        }
        if (dsf.isNumber(rule.minLength)) {
          if (dsf.validate("minLength", v, parseFloat(rule.minLength)) === true) {
            return new ValidateError(dsf.express.replace(errorMsg, rule), formName, {
              attributes: attr
            });
          }
        }
      }
      else if (rule.type == 'express') {
        if (dsf.isUnDef(v) || v === '') {
          continue;
        }
        if (dsf.validate(rule.express, v) == false) {
          return new ValidateError(dsf.express.replace(errorMsg, rule), formName, {
            attributes: attr
          });
        }
      }
    }

  }

}
