import control from "./control";
import enums from '../utils/enums'
let view = {
  mixins: [control],
  provide() {
    return {
      $page: this
    }
  },
  props: {
    isLoaded: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ""
    },
    serial: {
      type: Number,
      default: 0
    },
    isView: {
      type: Boolean,
      default: true
    },
    context: {
      type: Object,
      default() {
        return {
          B: "",
          M: ""
        };
      }
    },
    clientVersion: {
      type: String,
      default: "2.0"
    },
    title: {
      type: String,
      default: ""
    },
    //页头，页脚宽度
    headfootwidth: {
      type: String,
      default: "same"
    },
    fit: {
      type: [String, Number, Boolean],
      default: "1"
    },
    fitInModel: {
      type: [String, Number, Boolean],
      default: "1"
    },
    //页头页脚与主干间隙
    topBottom: {
      type: String,
      default: ""
    },
    //左右两侧与主干间隔
    leftRight: {
      type: String,
      default: ""
    },
    //左中右两侧视图宽度
    viewSplit: {
      type: [Array, String],
      default() {
        return ["0px", "auto", "0px"]
      }
    },
    //左中右伸缩比(废弃)
    viewSplitFlex: {
      type: Array,
      default() {
        return []
      }
    },
    //页面宽度
    pageWidth: {
      type: String,
      default: ""
    },
    //是否显示页头
    hasHeader: {
      type: Boolean,
      default: true
    },
    //是否显示页脚
    hasFooter: {
      type: Boolean,
      default: true
    },
    //页面权限
    pagePrivilege: {
      type: Array,
      default() {
        return [];
      }
    },
    pageType: {
      type: String,
      default: "other"
    },
    xssConfig: {
      type: Object,
      default() {
        return {
          allow: false
        };
      }
    },
    customCss: {
      type: String,
      default: ""
    },
    //页脚是否固定
    footFixed: {
      type: Boolean,
      default: true
    },
    needLogin: {
      type: Boolean,
      default: true
    },
    footerBackground: {
      type: String,
      default: "transparent"
    },
    dataAccessRole: {
      type: Array,
      default() {
        return []
      }
    },
    mode: {
      type: String,
      default: enums.viewMode.DEVELOP
    },
    waterMark: {
      type: Boolean,
      default: true
    },
    requests: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      $serialNum: this.serial
    };
  },
  computed: {
    nameSpace() {
      return this.context.B + "." + this.context.M;
    },
    $isLoaded() {
      let isDesign = this.isDesign;
      let isLoaded = this.isLoaded;
      if (isDesign) {
        return true;
      } else {
        return isLoaded;
      }
    },
    $isPrototype() {
      return this?.mode == enums.viewMode.PROTOTYPE;
    }
  },
  destroyed() { },
  mounted() { },
  beforeMount() {
  },
  created() {
    if (!this.isDesign && this.$vm) {
      this.$vm?.$pageCreated?.(this)
    }
  },
  methods: {
    getSerial() {
      let num = ++this.$data.$serialNum;
      this.$emit("update:serial", num);
      return num;
    },
    formSave() {
      if (this.isDesign) {
        return;
      }
    }
  },
  design: {
    isMask: false
  }
};
export default view;