import propertyDialogSetting from '../../propertyDialogSetting'
export default {
  mixins: [propertyDialogSetting],
  data() {
    return {
      tableData: [],
      names: [],
    };
  },
  created() {
    let data = this.currentData;
    for (let i = 0; i < data.length; i++) {
      const element = data[i];
      if (typeof element.more == "string" || !element.more) {
        this.$set(element, "more", {
          url: element.more || "",
        });
      }
    }
    this.tableData = data || [this.newTab()];
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      let res = "";
      if (row.name) {
        this.names[rowIndex] = "";
        let i = this.names.indexOf(row.name);
        if (i != -1 && i < rowIndex) {
          res = "warning-row";
        }
        this.names[rowIndex] = row.name;
      }
      return res;
    },
    newTab() {
      return {
        title: "Tab" + (this.tableData.length + 1),
        name: "tab" + (this.tableData.length + 1),
        hide: false,
        badge: null,
        lazy: false,
        url: "",
        controls: [],
        more: {},
      };
    },
    add() {
      // 新增
      this.tableData.push(this.newTab());
    },
    insert(scope) {
      // 插入
      this.tableData.splice(scope.$index + 1, 0, this.newTab());
    },
    deleted(scope) {
      this.tableData.splice(scope.$index, 1);
    },
    up(scope) {
      if (scope.$index > 0) {
        let curr = this.tableData[scope.$index];
        this.tableData.splice(scope.$index, 1);
        this.tableData.splice(scope.$index - 1, 0, curr);
      }
    },
    down(scope) {
      if (this.tableData.length - 1 > scope.$index) {
        let curr = this.tableData[scope.$index];
        this.tableData.splice(scope.$index, 1);
        this.tableData.splice(scope.$index + 1, 0, curr);
      }
    },
    validate() {
      for (let i in this.tableData) {
        this.tableData[i]["name"] = this.tableData[i]["name"] || dsf.uuid();
        let index = this.names.indexOf(this.tableData[i]["name"]);
        if (index != -1 && index < i) {
          dsf.layer.message("请解决重复标识", false);
          return false;
        }
      }
      return true;
    },
    yes() {
      return this.tableData;
    },
    checkMaxSpan(row, key) {
      row[key] = dsf.isNumber(row[key]) ? Number(row[key]) : null;
      if (row[key] && row[key] > 24) {
        row[key] = 24;
      }
      if (row[key] && row[key] < 0) {
        row[key] = null;
      }
    },
    toUrlSet(formModel, key) {
      if (!formModel[key]) this.$set(formModel, key, {});
      this.$openDialog({
        title: "跳转配置",
        content: "ToUrlEdit",
        height: "400px",
        width: "700px",
        params: {
          currentData: formModel[key],
        },
        btns: [
          {
            text: "确定",
            handler: (res) => {
              this.$set(formModel, key, res.yes());
            },
          },
          {
            text: "取消",
          },
        ],
      });
    },
  },
};