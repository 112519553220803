
export default{
  viewMode:{
    PROTOTYPE:"prototype",
    DEVELOP:"develop"
  },
  numberBoundary:{
    MIN:-9999999999999,
    MAX:9999999999999

  }
}