/* eslint-disable no-unused-vars */
// 混入主题
import { wsInstall } from "./ws";
import systemGlobalSetting from "../mixins/systemGlobalSetting";
import multiRouter from './multiRouter.js';

let designPages = {};
let routeLoadedState = {};


//初始化vue-router,加入默认守卫和重写其中部分函数
function initRouter(router, clientString) {
  //修正vue-router在跳转地址与当前路由相同的情况下报错
  const VueRouterPush = router.push;
  const VueRouterReplace = router.replace;
  if (dsf.isMultiPage()) {
    let _init = router.init;
    router.init = dsf.noop;
    router.multiInit = overRouteInit;
  }
  router.push = function push(to) {
    return VueRouterPush.call(this, to).catch((err) => {
      dsf.warn(err);
    });
  };
  router.replace = function replace(to) {
    let p = VueRouterReplace.call(this, to);
    p.catch((err) => {
      dsf.warn(err);
    });
    return p;
  };

  //检查动态组件（通过设计器配置的页面），并动态创建路由
  if (clientString) {
    router.beforeEach((to, from, next) => {
      let matched = to.matched;
      //当没有匹配到路由或者匹配到*路由时
      let isMatched = matched.length <= 0 || matched[0].path == "*";
      let clientPart = "/" + clientString + "/";
      if (isMatched && to.path.startsWith(clientPart) && !designPages[to.path]) {
        /**
         * 动态路由
         */
        let rmCode = to.path.substr(clientPart.length).replace(/\//g, ".");
        let rmCodeArr = rmCode.split(".");
        let nameSpace = rmCodeArr.slice(0, rmCodeArr.length - 1).join(".");
        let pageName = rmCodeArr[rmCodeArr.length - 1];
        if (!routeLoadedState[rmCode]) {
          routeLoadedState[rmCode] = {
            state: "loading",
            cbs: [next]
          };
        }
        else if (routeLoadedState[rmCode].state == "loading") {
          routeLoadedState[rmCode].cbs.push(next)
          return;
        }
        dsf.dynamicComponent(rmCode, clientString, true)
          .then((result) => {
            let pageTitle = dsf.express.replace(result.pageTitle, dsf.config)
            let addRouteFn = null;
            let routeConfig = {
              path: to.path,
              name: result.name,
              component: result,
              meta: {
                fromDesigner: true,
                needLogin: result.needLogin,
                title: pageTitle,
                keepAlive: result.isKeepAlive,
                level: result.pageLevel || 1,
                waterMark: result.waterMark
              },
              props(router) {
                return {
                  nameSpace: nameSpace,
                  pageName: pageName,
                  queryString: router.query
                };
              }
            }
            let routes = router.getRoutes();
            let index = _.findIndex(routes, (it) => {
              return it.path == routeConfig.path;
            })
            if (index <= 0) {
              //vue-router版本兼容,addRoutes函数超过3.5.1后被取消，要用router.addRoute代替
              router.addRoute ? router.addRoute(routeConfig) : router.addRoutes([routeConfig]);
            }
            routeLoadedState[rmCode].state = "loaded"
            designPages[to.path] = true;
            _.each(routeLoadedState[rmCode].cbs || [], (it) => {
              it({ path: to.fullPath });
            })
            // next({ path: to.fullPath });
          })
          .catch((err) => {
            dsf.error("动态组件加载失败请查看html请求是否为404或500,或者vue-router版本过低")
            routeLoadedState[rmCode].state = 'error'
            _.each(routeLoadedState[rmCode].cbs || [], (it) => {
              it();
            })
          });
      } else {
        next();
      }
    });
  }

  router.onError((error) => {
    dsf.layer.message("动态路由发生错误", false);
  });
  //将路由参数外放到window全局对象，使dsf.express表达式获取url参数功能可用
  router.afterEach((to, from) => {
    clientString === 'pc' && iframeDialogTitle(to.meta.title)
  });
}

//重置路由初始化函数，多页签模式下让其不触发页面路径变化时触发路由守卫
function overRouteInit(app) {
  var _this = this;
  this.apps.push(app);
  // set up app destroyed handler
  // https://github.com/vuejs/vue-router/issues/2639
  app.$once('hook:destroyed', function () {
    // clean out app from this.apps array once destroyed
    var index = _this.apps.indexOf(app);
    if (index > -1) { _this.apps.splice(index, 1); }
    // ensure we still have a main app or null if no apps
    // we do not release the router so it can be reused
    if (_this.app === app) { _this.app = _this.apps[0] || null; }

    if (!_this.app) { _this.history.teardown(); }
  });

  // main app previously initialized
  // return as we don't need to set up new history listener
  if (this.app) {
    return
  }
  this.app = app;

  // var history = this.history;

  // if (history instanceof HTML5History || history instanceof HashHistory) {
  //   var handleInitialScroll = function (routeOrError) {
  //     var from = history.current;
  //     var expectScroll = this$1.options.scrollBehavior;
  //     var supportsScroll = supportsPushState && expectScroll;

  //     if (supportsScroll && 'fullPath' in routeOrError) {
  //       handleScroll(this$1, routeOrError, from, false);
  //     }
  //   };
  //   var setupListeners = function (routeOrError) {
  //     history.setupListeners();
  //     handleInitialScroll(routeOrError);
  //   };
  //   history.transitionTo(
  //     history.getCurrentLocation(),
  //     setupListeners,
  //     setupListeners
  //   );
  // }

  // history.listen(function (route) {
  //   this$1.apps.forEach(function (app) {
  //     app._route = route;
  //   });
  // });
}

//更新iframe弹出层的标题，前提是使用了DsfIframeDialog
function iframeDialogTitle(title) {
  if (window?.frameElement?.$dialog?.$parent) {
    if (!window?.frameElement?.$dialog?.$parent.title) {
      window.frameElement.$dialog.$parent.title = title;
    }
  }
}
//全局混入
function globalMixin(Vue, context) {
  if (context) {
    Vue.mixin(context);
  }
}
//预加载动态页面路由
function preloads(paths, router, clientString) {
  let routers = {};
  _.each(paths, (el) => {
    routers[el.href] = false;
  });
  return new Promise((resolve, reject) => {
    if (paths.length <= 0) {
      resolve();
    }
    _.each(paths, (e, i) => {
      let clientPart = "/" + clientString + "/";
      let path = e.href;
      let rmCode = path.substr(clientPart.length).replace(/\//g, ".");
      let rmCodeArr = rmCode.split(".");
      let nameSpace = rmCodeArr.slice(0, rmCodeArr.length - 1).join(".");
      let pageName = rmCodeArr[rmCodeArr.length - 1];
      dsf.dynamicComponent(rmCode, clientString, true)
        .then((result) => {
          let pageTitle = dsf.express.replace(result.pageTitle, dsf.config);
          let routeConfig = {
            path: path,
            name: result.name,
            component: result,
            meta: {
              needLogin: result.needLogin,
              title: pageTitle,
              keepAlive: result.isKeepAlive,
              level: result.pageLevel || 1,
              waterMark: result.waterMark
            },
            props(router) {
              return {
                nameSpace: nameSpace,
                pageName: pageName,
                queryString: router.query
              };
            }
          }
          let routes = router.getRoutes();
          let index = _.findIndex(routes, (it) => {
            return it.path == routeConfig.path;
          })
          if (index <= 0) {
            //vue-router版本兼容,addRoutes函数超过3.5.1后被取消，要用router.addRoute代替
            router.addRoute ? router.addRoute(routeConfig) : router.addRoutes([routeConfig]);
          }
          designPages[path] = true;
        })
        .catch((err) => { })
        .finally(() => {
          // console.log(e);
          routers[e.href] = true;
          let result = _.filter(routers, (el) => !el);
          if (result.length <= 0) {
            resolve();
          }
        });
    });
  });
}

//初始化平台
function initPlatform(Vue, options = {}) {

  //判断网络连接
  if (window && dsf.config.setting_public_watchnetonline) {
    if (dsf.isDef(window?.navigator?.onLine) && !window.navigator.onLine) {
      dsf.layer.message('网络连接异常', false)
    }
    window.addEventListener('offline', () => {
      dsf.layer.message('网络连接异常', false)
    })
    window.addEventListener('online', () => {
      dsf.layer.message('网络已连接')
    });
  }

  Vue.use(multiRouter);
  //对window对象注入_spv_对象
  if (!window._spv_) {
    Object.defineProperty(window, '_spv_', {
      get() {
        let topWindow = dsf.getTopWindow();
        let spv = topWindow.__multiPageVm__;
        return spv || null;
      }
    });
  }

  //页面加载
  window.addEventListener("load", () => {
    if (window._spv_ && window?.frameElement && dsf.isDef(window.frameElement.getAttribute("dsf-spv"))) {
      if (window.frameElement.opener && !window.frameElement.opener.closed) {
        try {
          window.opener = window.frameElement.opener;
          window?.frameElement?.setTitle?.(document.title)
        }
        catch (ex) {
          dsf.warn(ex);
        }
      }
    }
  });
  if (window.$isMobile) {
    //如果页面初始状态存在van-number-keyboard标记，则删除（多个版本前遗留的一个bug）会导致样式问题
    let vanNumberKeyboard = document.body.querySelector('van-number-keyboard');
    if (vanNumberKeyboard) {
      document.body.removeChild(vanNumberKeyboard)
    }
    let iphoneSafari = dsf.client.info.os == 'iOS' && dsf.client.safari() && dsf.client.info.version.startsWith('15.');
    iphoneSafari ? document.body.classList.add("ios-safari-browser") : document.body.classList.remove("ios-safari-browser")
  }
  dsf.global.Vue = Vue;
  Vue.prototype.dsf = dsf;

  Vue.prototype.pxToRem = function (v) {
    return dsf.pxToRem(v);
  };
  Vue.prototype.unitSize = function (v, unit = 'px') {
    if (dsf.isArray(v)) {
      return _.map(v, (it) => {
        return this.unitSize(it, unit);
      });
    }
    else {
      if (unit == 'px') {
        return dsf.px(v);
      }
      else if (unit == 'rem') {
        return this.pxToRem(v);
      }
    }

  }

  //为vue对象注入表达式替换
  Vue.prototype.$replace = function (express, varible, isUrlDecode) {
    if (isUrlDecode) {
      express = express.replace(/%40%5b(((?!%5d).)*)%5d/gi, (a, b) => {
        return "@[" + decodeURIComponent(b) + "]"
      });
    }
    if (dsf.isUnDef(varible)) {
      varible = createExpressVarible(this);
    }
    // varible = dsf.mix(true, defaultVarible || {}, varible)
    return dsf.express.replace.call(this, express, varible);
  };
  //为vue对象注入表达式执行
  Vue.prototype.$eval = function (express, varible, isUrlDecode) {
    if (isUrlDecode) {
      express = express.replace(/%40%5b(((?!%5d).)*)%5d/gi, (a, b) => {
        return "@[" + decodeURIComponent(b) + "]"
      });
    }
    if (dsf.isUnDef(varible)) {
      varible = createExpressVarible(this);
    }
    // let defaultVarible = createExpressVarible(this);
    // varible=Object.create(varible,defaultVarible||{})

    return dsf.express.eval.call(this, express, varible);
  };
  //为vue对象注入打开新窗口函数
  Vue.prototype.$openWindow = function () {
    let args = Array.prototype.slice.call(arguments, 0);
    let vm = this.$vm ? this.$vm : this;
    return dsf.layer.openWindow.apply(vm, args);
  };
  Vue.prototype.$openDialog = function () {
    let args = Array.prototype.slice.call(arguments, 0);
    let vm = this.$vm ? this.$vm : this;
    args.push(() => {
      vm = null;
      args = null;
    })
    return dsf.layer.openDialog.apply(vm, args);
  };
  Vue.prototype.$message = function () {
    let args = Array.prototype.slice.call(arguments, 0);
    let vm = this.$vm ? this.$vm : this;
    return dsf.layer.message.apply(vm, args);
  }
  Vue.prototype.$confirm = function () {
    let args = Array.prototype.slice.call(arguments, 0);
    let vm = this.$vm ? this.$vm : this;
    return dsf.layer.confirm.apply(vm, args);
  }
  Vue.prototype.$alert = function () {
    let args = Array.prototype.slice.call(arguments, 0);
    let vm = this.$vm ? this.$vm : this;
    return dsf.layer.alert.apply(vm, args);
  }
  Vue.prototype.$loading = function () {
    let args = Array.prototype.slice.call(arguments, 0);
    let vm = this.$vm ? this.$vm : this;
    return dsf.layer.loading.apply(vm, args);
  }
  Vue.prototype.$closeLoading = function () {
    let args = Array.prototype.slice.call(arguments, 0);
    let vm = this.$vm ? this.$vm : this;
    return dsf.layer.closeLoading.apply(vm, args);
  }
  // 打开地址
  Vue.prototype.$open = function ({ url = "", state = 4, ...args }) {
    let topWin = dsf.getTopWindow();
    let result = {
      closed: () => { }
    }
    if (this.isDesign || !url) return result;
    if (!state) {
      state = 4

    }
    if (topWin.__multiPageVm__) {
      if (state != 3) {
        state = 2;
      }
    }
    setTimeout(() => {
      var isRoute = url.startsWith("#/")
      switch (state.toString()) {
      // // 主窗口打开
      // case "1":
      //   if (isRoute) {
      //     location.href = url;
      //   }
      //   else {
      //     location.href = dsf.url.getWebPath(url);
      //   }
      //   break;
      // 新窗口打开
      case "2":
        let win = this.$openWindow(url);
        if (win && win.document) {
          let timeId = setInterval(() => {
            if (win.document && win.closed) {
              if (dsf.isDef(args.close)) {
                result.closed(win);
              }
              clearInterval(timeId);
            }
          }, 1000);
        }
        break;
        // 对话框打开
      case "3":
        if (!args) args = {}
        let dialogOptions = {
          title: "",
          width: "70vw",
          height: "60vh",
          closed: () => {
            result.closed()
          },
          ...args
        }
        if (!dialogOptions.params) dialogOptions.params = {}
        if (/.*\#\/iframeloader.*/.test(url)) {
          dialogOptions.content = "DsfDialogIframe";
          let urlInfo = dsf.url.parse(url);
          dialogOptions.params.rmiframeUrl = urlInfo.hashQuery.src;
        } else {
          dialogOptions.params.path = url
        }
        this.$openDialog(dialogOptions);
        break;
        // 当前窗口打开
      default:
        if (isRoute) {
          location.href = url;
        }
        else {
          location.href = dsf.url.getWebPath(url);
        }
        break;
      }
    }, 0)
    return result
  };
  //获取真实静态数据
  Vue.prototype.$getRealStaticValue = function (v) {
    if (dsf.isString(v)) {
      v = this.$replace(v)
      if (dsf.type(v) == 'date') {
        v = dsf.date.format(v, 'yyyy-mm-dd');
      }
    }
    else if (dsf.isObject(v)) {
      // debugger;
      let ov = v;
      v = {};
      for (let k in ov) {
        v[k] = this.$getRealStaticValue(ov[k]);
      }
    }
    else if (dsf.isArray(v)) {
      let ov = v;
      v = [];
      for (let i = 0; i < ov.length; i++) {
        let obj = this.$getRealStaticValue(ov[i]);
        v.push(obj)
      }
    }
    return v;
  };
  Vue.prototype.$expressParams = function (data, props) {
    let obj = {
      $vm: {
        value: this?.$vm
      },
      $viewData: {
        value: this?.$vm?.$viewData
      },
      $self: {
        value: this
      }
    }
    if (props) {
      for (let k in props) {
        obj[k] = {
          value: props[k]
        }
      }
    }
    return Object.create(data || {}, obj)
  }

  dsf.$systemGlobalSetting = systemGlobalSetting;

  window.$$unitTest = false;
  const i18nNameSpace = "namespace.";
  Vue.mixin({
    beforeCreate() {
      if (this == this.$root) {
        if (!this.$root._mulitRoutes) {
          Vue.util.defineReactive(this.$root, '_mulitRoutes', {})
          Vue.util.defineReactive(this.$root, '_mulitRoutesArray', [])
        }
      }
    }
  })
  //多页签混入路由方法
  if (dsf.isMultiPage()) {
    const registerInstance = (vm, callVal) => {
      let i = vm.$options._parentVnode
      if (dsf.isDef(i) && dsf.isDef(i = i.data) && dsf.isDef(i = i.registerRouteInstance)) {
        i(vm, callVal)
      }
    }
    //查找vue注入的组件中vue-router
    const VueRouter = _.find(Vue._installedPlugins, (it) => {
      return dsf.isDef(it.NavigationFailureType) && dsf.isDef(it.isNavigationFailure);
    })
    Vue.mixin({
      beforeCreate() {
        if (dsf.isDef(this.$options.multiView)) {
          this._routerRoot = this;
          this._router = new VueRouter(this.$root.$options.router.options);
          const history = this._router.history
          this._router = dsf.mix(true, this._router, this.$root.$options.router);
          this._router.history = history;
          if (this._router.multiInit) {
            this._router.multiInit(this);
            Vue.util.defineReactive(this, '_route', this._router.history.current)
          }
        }
        else {
          this._routerRoot = (this.$parent && this.$parent._routerRoot) || this;
        }
        registerInstance(this, this)
      },
      destroyed() {
        registerInstance(this)
      }
    })
  }
  Vue.mixin({
    beforeCreate() {

      //创建$http访问入口
      injectionHttp.call(this);
      //创建共同的webpi访问入口
      injectionWebAPI.call(this);
      //输入系统皮肤、字体配置
      injectionSystemSetting.call(this);
    },
    created() {
      if (this.$i18n) {
        this.$$t = this.$t;
        this.$t = function (key) {
          if (key.startsWith(i18nNameSpace) && this.$vm) {
            key = key.replace(i18nNameSpace, "$" + this.$vm.$options.name + ".")
          }
          return this.$$t(key);
        }
      } else {
        //兼容一下没有i18n的情况,创建一个空的$t函数
        this.$t = function (key) {
          return key;
        }
      }
    },
    mounted() {
      //开启单元测试
      if (window.$$unitTest === true) {
        //判断_fromDesigner为true表示平台配置的页面
        this.$data._fromDesigner && attachUnitTest.call(this, "loaded");
      }
    },
    computed: {
      ...(dsf.global.$isMobile ? mobileDefaultComputed() : {})
    },
    beforeRouteEnter(to, from, next) {
      next((vm) => {
        //开启单元测试
        vm.$unitTest();
        //自动打开或者关闭水印
        vm.$autoWaterHandler(to, from)
      })
    },
    methods: {
      $unitTest() {
        if (window.$$unitTest === true) {
          (!this.$data._fromDesigner) && attachUnitTest.call(vm, "loaded");
        }
      },
      $warterOn(state) {
        warterOn(this, state);
      },
      $autoWaterHandler(to, from) {
        //防截图水印,判断开启水印,且页面必须是需要登录时
        let warter_on = false;
        if (dsf.config.setting_public_on_watermark && dsf.getToken()) {
          //如果路由中有waterMark存在则以页面的为准,如果路由中不存在waterMark存在则配置为准
          warter_on = dsf.isDef(to?.meta?.waterMark) ? to.meta.waterMark : dsf.config.setting_public_default_watermark;
        }
        else {
          warter_on = false;
        }
        this.$warterOn(warter_on);
      },
      ...(dsf.global.$isMobile ? mobileDefaultFunction() : {})
    }
  });
  // 全局混入ws
  Vue.use(wsInstall);
}

//水印开关
function warterOn(vm, state) {
  vm.$store.commit("userInfoWaterMark/" + (state ? 'on' : 'off'));
}

function mobileDefaultFunction() {
  try {
    let fns = Vuex.mapMutations("refreshQueue", {
      "$pushRefresh": "add",
      "$removeRefresh": "remove"
    });
    return fns;
  }
  catch (ex) {
    return {}
  }
}
function mobileDefaultComputed() {
  try {
    let fns = Vuex.mapState("refreshQueue", {
      "$refreshQueue": "queue"
    });
    return fns;
  }
  catch (ex) {
    return {}
  }
}

function attachUnitTest(fnName) {
  if (window.$$unitTest === true) {
    if (dsf.unitTest.pages[this.$route.fullPath]) {
      let query = this.queryString ? this.queryString : this.$route.query;
      let method = null;
      if (dsf.unitTest.pages[this.$route.fullPath][fnName]) {
        method = dsf.unitTest.pages[this.$route.fullPath][fnName];
      }
      if (fnName == 'loaded') {
        if (this.nameSpace) {
          this.$watch("$isReady", function (v, ov) {
            v && method && method(this, query);
          });
        }
        else {
          method && method(this, query);
        }
      }
    }
  }
}

function httpProxy(methodName, path, params, options, baseUrl) {
  if (dsf.type(options) == 'string' || dsf.type(options) == 'boolean') {
    baseUrl = options;
    options = null;
  }
  if (dsf.type(baseUrl) == 'boolean') {
    //为true表示强行在$vm指定的微服务前缀下调用接口
    if (baseUrl === true) {
      //如果当前组件继承了control则可以直接过去到当前页面微服务名称
      let servicePrefix = ""
      if (this.$servicePrefix) {
        servicePrefix = this.$servicePrefix || "";
      }
      else {
        let component = this
        while (!component?.$data?._isLayoutView && component.$parent) {
          component = component.$parent;
          if (component?.$data?._isLayoutView || component == component.$root) {
            break;
          }
        }
        if (component?.$data?._isLayoutView) {
          servicePrefix = component?.$servicePrefix;
        }
      }
      if (servicePrefix) {
        path = dsf.url.getWebPath(path, servicePrefix);
        baseUrl = "/";
      }
      else {
        baseUrl = "";
      }
      // path = servicePrefix ? dsf.url.getWebPath(path, servicePrefix) : path;
    }

  }
  let httpFn = dsf.http[methodName](path, params, options, baseUrl);
  return httpFn;
}

function injectionWebAPI() {
  let api = dsf.api.getAPI();
  Object.defineProperty(this, "$webAPI", {
    get() {
      let _this = this;
      if (!_this.$$webapi) {
        _this.$$webapi = {};
        for (let k in api) {
          _this.$$webapi[k] = api[k].bind(_this);
        }
      }
      return _this.$$webapi;
    },
    enumerable: false
  });
}

function injectionHttp() {
  let _dsf = this.$options.dsf || this.dsf;
  let _this = this;
  Object.defineProperty(this, '$http', {
    get: function () {
      if (!_this.$$http) {
        let $$http = dsf.mix(true, {}, _dsf.http, {
          get() {
            let args = Array.prototype.slice.call(arguments, 0);
            return httpProxy.apply(_this, ['get', ...args]);
          },
          post() {
            let args = Array.prototype.slice.call(arguments, 0);
            return httpProxy.apply(_this, ['post', ...args]);
          },
          upload() {
            let args = Array.prototype.slice.call(arguments, 0);
            return httpProxy.apply(_this, ['upload', ...args]);
          }
        })
        Object.defineProperty(_this, "$$http", {
          value: $$http,
          enumerable: false
        });
      }
      return _this.$$http;
    },
    enumerable: false
  });
}
//注入系统级别访问变量
function injectionSystemSetting() {
  Object.defineProperty(this, "$system", {
    get() {
      //皮肤，字体
      return {
        setting: dsf.themeConfig.setting
      }
    },
    enumerable: false
  })
}

function createExpressVarible(com, varible) {
  let vm = ('$vm' in com) ? com.$vm : null;
  let local = ('local' in com) ? com.local : null;
  //如果不是配置页面$vm是不存在的，当然如果非要伪造一个$vm我也没办法
  if (!vm) {
    return null;
  }
  if (dsf.isDef(local)) {
    if (!local.$vm) {
      local = Object.create(local, {
        '$vm': {
          value: vm
        },
        '$viewData': {
          value: vm.$viewData
        }
      });
    }
    return local
  }
  else {
    local = {};
    local = Object.create(local, {
      '$vm': {
        value: vm
      },
      '$viewData': {
        value: vm.$viewData
      }
    });
    return local

  }
}

export default {
  globalMixin,
  preloads,
  initRouter,
  initPlatform
};
