import control from "./control";
export default {
  mixins: [control],
  inject: {
    $list: {
      default: null
    },
    $cardList: {
      default: null
    },
  },
  props: {
    buttons: {
      type: Array,
      default() {
        return [];
      }
    },
    keysMap: {
      type: [Array, Function],
      default() {
        return [];
      }
    },
    local: {
      type: Object,
      default() {
        return {}
      }
    },
    clickBefore: {
      type: Function,
      required: false
    },
    // 图片加载失败的替代图片
    errorSrc: {
      type: String,
      default: ''
    }
  },
  computed: {
    keysObj() {
      let obj = {};
      _.forEach(this.keysMap, m => {
        obj[m.to] = m
      });
      return obj;
    },
    data() {
      let res = {};
      _.forEach(this.keysMap, ({ to, from, type }) => {
        let d = this.local[from];
        if (type === 'Boolean') {
          res[to] = !!d;
        } else {
          res[to] = d;
        }
      });
      return res;
    },
    checked: {
      get() {
        let key = this.$cardList.rowKey;
        return this.$cardList.checkIdList.indexOf(this.local[key]) > -1;
      },
      set(val) {
        this.$cardList.checkChange(this.local, !!val);
      }
    },
    disabled() {
      return this.$cardList.selectableHandler(this.local, this.local.$index);
    }
  },
  methods: {
    getLabel(key) {
      return this.keysObj[key].label || '';
    },
    getPrefix(key) {
      return this.keysObj[key].prefix || '';
    },
    getSuffix(key) {
      return this.keysObj[key].suffix || '';
    },
    _clickBefore(next, ...params) {
      if (this.isDesign) return;
      let res = this.clickBefore?.(this.local);
      if (res && res.then) {
        res.then(r => {
          if (r !== false) {
            next(...params);
          }
        });
      } else if (res !== false) {
        next(...params);
      }
    },
    clickAfter(...args) {
      this.$nextTick(() => {
        this.$dispatch('click-after', args);
      });
    }
  }
}