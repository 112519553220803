import layout from "./layout";
export default {
  mixins: [layout],
  inject: {
    $list: {
      default: null
    }
  },
  provide() {
    return {
      $cardList: this
    };
  },
  props: {
    // 行key
    rowKey: {
      type: [String, Function],
      default: "_id"
    },
    // 外部数据
    data: {
      type: Array,
      default: null,
      // default() {
      //   return [{},{},{},{},{},{}]
      // }
    },
    // 加载数据的url
    url: {
      type: String,
      default: ""
    },
    // 是否水平滚动
    horizontal: {
      type: Boolean,
      default: false
    },
    // 一行N列
    columnNumber: {
      type: Number,
      default: 3
    },
    // 列宽
    columnWidth: {
      type: String,
      default: '32%'
    },
    // 列高
    lineHeight: {
      type: String,
      default: '120px'
    },
    // 上下间隙
    marginTop: {
      type: String,
      default: '15px'
    },
    // 左右间隙
    marginLeft: {
      type: String,
      default: '15px'
    },
    // 是否存在checkbox
    checkbox: {
      type: Boolean,
      default: false
    },
    // 选择框样式
    checkboxStyle: {
      type: String,
      default: 'style1'
    },
    // 最多能选中几个，0无限制，1单选
    maxCheckNum: {
      type: Number,
      default: 0
    },
    // 是否显示全选按钮
    showCheckAll: {
      type: Boolean,
      default: true
    },
    // 类型为 Function，Function(row, index) 的返回值用来决定这一行的 CheckBox 是否可以勾选
    // 类型为 Array，需要禁用选择的行id
    selectable: {
      type: [Array, Function],
      required: false
    },
    slots: {
      type: Array,
      default() {
        return [
          {
            name: 'default',
            controls: [],
            scope: 'scope',
            dataKey: 'row',
            textProxy: true,
            repeat: true
          }
        ]
      }
    },
    dataFormat: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      dataCopy: [],
      checkIdList: [],
      checkDataList: []
    }
  },
  computed: {
    cWidth() {
      if (this.$isMobile) {
        return this.pxToRem(this.columnWidth);
      }
      return this.columnWidth;
    },
    lHeight() {
      if (this.$isMobile) {
        return this.pxToRem(this.lineHeight);
      }
      return this.lineHeight;
    },
    mTop() {
      if (this.$isMobile) {
        return this.pxToRem(this.marginTop);
      }
      return this.marginTop;
    },
    listStyle() {
      if (!this.horizontal) return {};
      let padding = _.map(this.padding, p => this.pxToRem(p));
      let height = `${this.columnNumber} * ${this.lHeight} + ${this.columnNumber - 1} * ${this.mTop}`;
      return {
        'height': `calc(${height})`,
        'padding-left': padding[3],
        'padding-right': padding[1]
      }
    },
    itemStyle() {
      let marginLeft = this.marginLeft;
      if (this.$isMobile) {
        marginLeft = this.pxToRem(marginLeft);
      }
      if (!this.horizontal) {
        marginLeft = `calc((100% - ${this.cWidth} * ${this.columnNumber}) / ${this.columnNumber - 1})`;
      }
      let res = {
        'width': this.cWidth,
        'margin-Left': marginLeft,
        'margin-top': this.mTop
      };
      if (this.horizontal) {
        res['height'] = this.lHeight;
      }
      return res;
    },
    realData() {
      if (this.$list) {
        return this.data;
      }
      if (this.data) {
        return _.map(this.data, (d, i) => {
          return Object.assign({
            $hash: dsf.uuid(),
            $order: i,
            $index: i
          }, d);
        });
      }
      return this.dataCopy;
    },
    // 最多可选择几个
    realMaxCheckNum() {
      if (!this.maxCheckNum) {
        return this.realData.length;
      }
      return Math.min(this.maxCheckNum, this.realData.length);
    },
    // 是否全选
    checkAll() {
      let checkNum = this.getCurrentCheckNum();
      return checkNum && checkNum >= this.realMaxCheckNum;
    },
    // 是否半选
    isIndeterminate() {
      let checkNum = this.getCurrentCheckNum();
      return checkNum > 0 && checkNum < this.realMaxCheckNum;
    }
  },
  watch: {
    url() {
      this.reloadData();
    }
  },
  created() {
    this.reloadData();
  },
  methods: {
    getItemClass(index) {
      let columnNumber = this.columnNumber;
      if (this.horizontal) {
        return {
          'no-margin-top': !(index % columnNumber),
          'no-margin-left': index < columnNumber,
        }
      }
      return {
        'no-margin-left': !(index % columnNumber),
        'no-margin-top': index < columnNumber,
      }
    },
    reloadData() {
      if (!this.isDesign && !this.data) {
        this.getUrlData();
      }
    },
    getUrlData() {
      if (!this.url) {
        this.dataCopy = [];
        return;
      }
      let loading = dsf.layer.loading();
      let url = this.$replace(this.url, this);
      this.$http.get(url).done(({success, data, message}) => {
        if (success) {
          if (dsf.type(this.dataFormat) === 'Function') {
            data = this.dataFormat(data);
          }
          this.dataCopy = _.map(data, (d, i) => {
            return Object.assign({
              $hash: dsf.uuid(),
              $order: i,
              $index: i
            }, d);
          });
        } else {
          this.$message(message || '请求异常', false);
        }
      }).error(err => {
        dsf.error(err);
        this.$message(err?.message || '请求异常', false);
      }).always(() => {
        dsf.layer.closeLoading(loading);
      })
    },
    // 获取当前页面选中的数量
    getCurrentCheckNum() {
      let n = 0;
      _.forEach(this.realData, row => {
        let key = row[this.rowKey];
        if (this.checkIdList.indexOf(key) > -1) {
          n++;
        }
      });
      return n;
    },
    // 检测当前数据是否可选择
    selectableHandler(row, index) {
      let key = row[this.rowKey];
      if (dsf.isArray(this.selectable)) {
        return this.selectable.indexOf(key) === -1;
      }
      if (dsf.isFunction(this.selectable)) {
        return this.selectable(row, index);
      }
      return true;
    },
    checkChange(row, val) {
      let key = row[this.rowKey];
      let index = this.checkIdList.indexOf(key);
      let disabled = !this.selectableHandler(row, row.$index);
      if (disabled) return;
      // 已选
      if (!val && index > -1) {
        this.checkIdList.splice(index, 1);
        this.checkDataList.splice(index, 1);
        this.$emit('select', this.checkDataList, row);
      }
      // 未选
      else if (val && index === -1) {
        // 单选
        if (this.maxCheckNum == 1) {
          this.checkIdList = [key];
          this.checkDataList = [row];
          this.$emit('select', this.checkDataList, row);
        }
        // 有个数限制
        else if (this.maxCheckNum > 1 && this.getCurrentCheckNum() >= this.maxCheckNum) {
          this.$message(`最多只能选择${this.maxCheckNum}条数据`, false);
        } else {
          this.checkIdList.push(key);
          this.checkDataList.push(row);
          this.$emit('select', this.checkDataList, row);
        }
      }
    },
    // 全选/全不选 事件
    handleCheckAllChange(val, isNotEmit) {
      // 全不选
      if (!val) {
        _.forEach(this.realData, (row, index) => {
          let key = row[this.rowKey];
          let i = this.checkIdList.indexOf(key);
          if (i > -1 && this.selectableHandler(row, index)) {
            this.checkIdList.splice(i, 1);
            this.checkDataList.splice(i, 1);
          }
        });
      }
      // 全选
      else {
        let ks = [], ds = [], n = this.realMaxCheckNum - this.getCurrentCheckNum();
        for (let i = 0, l = this.realData.length; i < l; i++) {
          let row = this.realData[i];
          let key = row[this.rowKey];
          let index = _.findIndex(this.realData, it => it[this.rowKey] === key);
          if (this.checkIdList.indexOf(key) === -1 && this.selectableHandler(row, index)) {
            ks.push(key);
            ds.push(row);
            n--;
            if (!n) break;
          }
        }
        this.checkIdList.push(...ks);
        this.checkDataList.push(...ds);
      }

      isNotEmit || this.$emit('select-all', this.checkDataList);
    },
    // 清空选项
    clearSelection() {
      this.checkIdList = [];
      this.checkDataList = [];
    },
    // 切换某一行的选中状态，如果使用了第二个参数，则是设置这一行选中与否（selected 为 true 则选中）
    toggleRowSelection(row, selected) {
      this.checkChange(row, selected);
    },
    // 用于多选，切换所有行的选中状态
    toggleAllSelection() {
      this.handleCheckAllChange(!this.checkAll, true);
    },
  }
}