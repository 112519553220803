import dsf from "../utils";

export default {
  type: "native",
  isEnv() {
    return dsf.client.native()
  },
  init() {
    return new Promise((resolve, reject) => {
      if (dsf.client.native()) {
        document.addEventListener(
          "deviceready",
          () => {
            this.methods.isDeviceready = true;
            if (this.methods._devicereadyEventListener.length) {
              this.methods._devicereadyEventListener.map((cb) => cb())
            }
            if (!window.dsfMobileLocationBack) {
              window.dsfMobileLocationBack = function () {
                window.location.back();
              };
            }
            if (!window.iFlytekTalkMsg) window.iFlytekTalkMsg = (msg) => {
              this.methods.recording._sendRtasrListener(msg)
            }
            if (!window.meetingExit) window.meetingExit = (msg) => {
              this.methods.videoMeeting._sendListener(msg)
            }
            if (!window.cordovaAtionCallback) window.cordovaAtionCallback = (res) => {
              this._cordovaAtionCallback.call(this, res)
            }
            if (!window.backbutton) {
              window.backbutton = backbutton
              document.addEventListener('backbutton', backbutton, false)
            }
            setTimeout(() => {
              resolve()
            }, 0)
          },
          false
        );
        let url = ""
        if (dsf.client.android()) {
          url = dsf.url.getWebPath("$/js/libs/cordova/android/cordova.js")
        }
        else if (dsf.client.iOS()) {
          url = dsf.url.getWebPath("$/js/libs/cordova/ios/cordova.js")
        }
        if (url) {
          this.methods.camera = this.methods.camera.scan
          dsf.http.importFiles(url)
            .then(([{ state }]) => {
              if (!state) {
                console.error("cordova.js文件出错")
                reject('cordova.js文件出错')
              }
            })
            .catch(err => {
              console.error(err)
              reject('cordova.js文件出错')
            })
        }
        else {
          resolve()
        }
      } else {
        reject()
      }
    });
  },
  _cordovaAtionCallback(res) {
    let { type, msg } = JSON.parse(res)
    console.log(type, msg)
    // let mappingName = this._onEventMappingNames[type]
    // if (mappingName) {
    //   this.$event.dispatch(mappingName, {
    //     originType: type,
    //     type: mappingName,
    //     desc: this.onEventNames[mappingName],
    //     args: msg
    //   })
    // }
  },
  methods: {
    _devicereadyEventListener: [],
    // 相机相关
    camera: {
      //扫描二维码
      scan() {
        return new Promise((resolve, reject) => {
          if (dsf.client.android() && window.$Barcode) {
            $Barcode.scan(
              function (res) {
                let result = res.text
                resolve(result)
              },
              function (error) {
                dsf.layer.message(error, false);
                reject(error)
              }
            );
          } else if (dsf.client.iOS() && window.$Scanner) {
            // ios 调用插件
            $Scanner.open(
              function (res) {
                let result = res.text
                resolve(result)
              },
              function (error) {
                dsf.layer.message(error, false);
                reject(error)
              }
            );
          } else {
            dsf.layer.message('无法调起扫码功能！', false)
            reject('无法调起扫码功能！')
          }
        })
      },
      // 计时拍照
      takePhoto(opt = {}) {
        opt = {
          // 计时
          time: 5,
          COUNT_DOWN: true,
          SHOW_BUTTON: false,
          ...opt
        };
        return new Promise((resolve, reject) => {
          _DsfRecord.takePhoto(opt, () => {
            resolve()
          }, () => {
            reject()
          })
        })
      }
    },
    // 应用就绪 api 可用
    deviceready(cb) {
      if (!dsf.type(cb) == 'function') return console.warn('deviceready 必须传入一个回调函数')
      if (this.isDeviceready) return cb()
      this._devicereadyEventListener.push(cb)
    },
    // 关闭开机画面
    hideSplashScreen() {
      if (dsf.client.android()) {
        if (navigator.splashscreen && navigator.splashscreen.hide) {
          navigator.splashscreen.hide();
        }
      } else if (dsf.client.iOS()) {
        if (window.WelcomePage && window.WelcomePage.hideen) {
          window.WelcomePage.hideen();
        }
      }
    },
    // 视频会议
    videoMeeting: {
      isConnected: false,
      videoMeetingListener: [],
      // 添加 人脸识别 回调
      addListener(cb) {
        if (!dsf.type(cb) == 'function') return console.warn('videoMeeting.addListener 必须传入一个回调函数')
        if (!this.videoMeetingListener.some(listener => listener === cb)) {
          this.videoMeetingListener.push(cb)
        }
      },
      // 删除 人脸识别 回调
      removeListener(cb) {
        if (!dsf.type(cb) == 'function') return console.warn('videoMeeting.removeListener 必须传入一个回调函数')
        this.videoMeetingListener = this.videoMeetingListener.filter(listener => listener !== cb)
      },
      // 内部使用  发送结果
      _sendListener(msg) {
        this.videoMeetingListener.map(listener => listener(msg));
      },
      connect(opt = {}) {
        opt = {
          Cookie: '',
          authorization_token: '',
          token: "5MNCgdH9OOcFuh5d+9vSUg6GBIzoAMqYKqJ5oz4J42k=@kw57.cn.rongnav.com;kw57.cn.rongcfg.com",
          ...opt
        }
        return new Promise((resolve, reject) => {
          try {
            if (this.isConnected) {
              return resolve()
            }
            $RIMOptionPlugin.connect(
              JSON.stringify(opt),
              (result) => {
                this.isConnected = true;
                resolve(result)
              },
              (err) => {
                reject(err)
              }
            );
          } catch (error) {
            reject(error)
          }
        })
      },
      meeting(opt = {}) {
        opt = {
          roomId: '',
          userName: '',
          meetingName: '',
          ...opt
        }
        return new Promise((resolve, reject) => {
          $RIMOptionPlugin.meeting(JSON.stringify(opt), (result) => {
            resolve(result)
          }, (error) => {
            reject(error)
          });
        })
      }
    },
    // 录音/语音
    recording: {
      rtasrListener: [],
      // 语音唤醒开始
      wakeStart() {
        return new Promise((resolve, reject) => {
          $IFlytek.wakeStart((data) => {
            resolve(data)
          }, (e) => {
            reject(e)
          })
        })
      },
      // 语音唤醒结束
      wakeStop() {
        return new Promise((resolve, reject) => {
          $IFlytek.wakeStop((data) => {
            resolve(data)
          }, (e) => {
            reject(e)
          });
        })
      },
      // 文字转语音开始
      startTts({ message }) {
        return new Promise((resolve, reject) => {
          $IFlytek.startTts(JSON.stringify({ message }), (data) => {
            resolve(data)
          }, (e) => {
            reject(e)
          });
        })
      },
      // 文字转语音结束
      stopTts() {
        return new Promise((resolve, reject) => {
          $IFlytek.stopTts((data) => {
            resolve(data)
          }, (e) => {
            reject(e)
          });
        })
      },
      // 添加 实时语音转写 回调
      addRtasrListener(cb) {
        if (!dsf.type(cb) == 'function') return console.warn('recording.addRtasrListener 必须传入一个回调函数')
        if (!this.rtasrListener.some(listener => listener === cb)) {
          this.rtasrListener.push(cb)
        }
      },
      // 删除 实时语音转写 回调
      removeRtasrListener(cb) {
        if (!dsf.type(cb) == 'function') return console.warn('recording.removeRtasrListener 必须传入一个回调函数')
        this.rtasrListener = this.rtasrListener.filter(listener => listener !== cb)
      },
      // 内部使用  发送结果
      _sendRtasrListener(msg) {
        this.rtasrListener.map(listener => listener(msg));
      },
      // 实时语音转写开始 
      rtasrStart() {
        return new Promise((resolve, reject) => {
          $rtasr.rtasrStart((data) => {
            resolve(data)
          }, (e) => {
            reject(e)
          });
        })
      },
      // 实时语音转写结束
      rtasrEnd() {
        return new Promise((resolve, reject) => {
          $rtasr.rtasrClose((data) => {
            resolve(data)
          }, (e) => {
            reject(e)
          });
        })
      },
      /**
       * 打开语音识别【短】
       * @param {*} opt 
       * {
       *  isShowDialog:false, 是否显示弹窗
       * }
       * @returns {Promise}
       */
      openShortSpeechRecognition(opt = {}) {
        return new Promise((resolve, reject) => {
          if (!$IFlytek) return reject(`没有注入$IFlytek这个组件`)
          $IFlytek?.startSpeech(JSON.stringify({
            isShowDialog: false, // 是否要弹窗
            ...opt,
          }), function (data) {
            resolve(data)
          }, function (e) {
            reject(e)
          });
        })
      },
      /**
       * 关闭语音识别【短】
       */
      closeShortSpeechRecognition() {
        return new Promise((resolve, reject) => {
          if (!$IFlytek) return reject(`没有注入$IFlytek这个组件`)
          $IFlytek.stopSpeech(function (data) {
            resolve(data)
          }, function (e) {
            reject(e)
          });
        })
      }
    },
    // 人脸识别
    arcsoftFace: {
      isActivation: false,
      isInt: false,
      arcsoftFaceListener: [],
      // 激活人脸识别
      activeEngine(opt = {}) {
        opt = {
          arcSoftFaceVerificationCode: '0857-115T-A3EL-B4QW',
          ...opt
        }
        return new Promise((resolve, reject) => {
          if (this.isActivation) return resolve()
          $arcsoftface.activeEngine(JSON.stringify(opt), (data) => {
            this.isActivation = true
            resolve(data)
          }, (e) => {
            reject(e)
          })
        })
      },
      // 初始化
      init({ url }) {
        return new Promise((resolve, reject) => {
          if (this.isInt) return resolve()
          $arcsoftface.init(JSON.stringify({ url }), (data) => {
            resolve(data)
          }, (e) => {
            reject(e)
          });
        })
      },
      // 添加 人脸识别 回调
      addListener(cb) {
        if (!dsf.type(cb) == 'function') return console.warn('arcsoftFace.addListener 必须传入一个回调函数')
        if (!this.arcsoftFaceListener.some(listener => listener === cb)) {
          this.arcsoftFaceListener.push(cb)
        }
      },
      // 删除 人脸识别 回调
      removeListener(cb) {
        if (!dsf.type(cb) == 'function') return console.warn('arcsoftFace.removeListener 必须传入一个回调函数')
        this.arcsoftFaceListener = this.arcsoftFaceListener.filter(listener => listener !== cb)
      },
      // 内部使用  发送结果
      _sendListener(msg) {
        this.arcsoftFaceListener.map(listener => listener(msg));
      },
      // 开始认证
      goRegisterAndRecognize(opt = {}) {
        opt = {
          "width": 300,
          "height": 300,
          // 1 坐上 2 右上  3 右下  4  左下
          "type": 2,
          "margin1": 20,
          "margin2": 20,
          "isMirror": false,
          "mirrorHorizontalor": true,
          "mirrorVertical": false,
          ...opt
        }
        return new Promise((resolve, reject) => {
          if (!window.arcSoftWindowRecognizeSucceed) window.arcSoftWindowRecognizeSucceed = (msg) => {
            this._sendListener(msg)
          }
          $arcsoftface.goRegisterAndRecognize(JSON.stringify(opt), data => {
            resolve(data)
          }, e => {
            reject(e)
          });
        })
      },
      // 关闭认证
      closeRegisterAndRecognize() {
        return new Promise((resolve, reject) => {
          $arcsoftface.closeRegisterAndRecognize(data => {
            resolve(data)
          }, e => {
            reject(e)
          });
        })
      }
    },
    // 定位
    location: {
      // 获取百度定位
      getBD() {
        return new Promise((resolve, reject) => {
          $BaiduLocation.getLocation("", (res) => {
            res = JSON.parse(res)
            let address = {
              address: res.address,
              lng: res.longitude,
              lat: res.latitude,
              accuracy: res.accuracy,
              speed: res.speed,
            }
            resolve(address)
          }, (res) => {
            reject(res)
          });
        })
      }
    },
    // 获取设备码
    getDeviceSerialId() {
      return new Promise((resolve, reject) => {
        $DeviceIdPlugin?.getDeviceSerialId((data) => {
          resolve(data)
        }, (err) => {
          reject(err)
        });
      })
    },
    // 订阅照片回调
    subGlobalPhoto(cb) {
      if (!dsf.type(cb) == 'function') return console.warn('subGlobalPhoto 必须传入一个回调函数')
      if (!window.pluginCameraResult) {
        window.pluginCameraResult = (res) => {
          /**
           * res
           * {"file":"","suffix":"","name":"","size":""}
           */
          cb(res)
        }
      }
    },
    // 华为大屏
    huaweiBigScreen: {
      // 打开白板
      openWhiteboard(opt = {}) {
        opt = {
          "packageName": "com.dsfa.hwstudy.compound",
          "activityName": "com.dsfa.hwstudy.compound.MainActivity",
          ...opt
        }
        return new Promise((resolve, reject) => {
          $iaction.openHuaweiWhiteboard(opt, (s) => {
            resolve(s)
          }, (e) => {
            reject(e)
          });
        })
      }
    },
    // wps 方法
    wps: {
      // 下载并打开
      downAndOpen(opt = {}) {
        opt = {
          // 文件下载路径
          downUrl: '',
          // 文件上传路径，""空字符串
          saveUrl: '',
          // 文件名称
          fileName: '',
          // 下载完成后是否用WPS打开
          openWithWPS: true,
          // 文件修改后是否上传
          uploadFile: false,
          // 自动播放
          autoPlay: false,
          // true 已下载直接开打, false每次都重新下载再打开
          readLocalFirst: false,
          // 文件打开模式
          openMode: "Normal",
          // 是否启动预览模式
          enterReviseMode: false,
          showReviewingPaneRightDefault: false,
          // 用户姓名
          userName: '',
          ...opt
        }
        return new Promise((resolve, reject) => {
          $WPS.downAndOpen(JSON.stringify(opt), (data) => {
            resolve(data)
          }, (error) => {
            reject(error)
          });
        })
      }
    },
    // 存储
    storage: {
      // 设置
      set(key, value) {
        return new Promise((resolve, reject) => {
          $sharedPreferences.save({ [key]: value }, () => {
            resolve()
          }, (e) => {
            reject(e)
          });
        })
      },
      // 获取
      get(key) {
        return new Promise((resolve, reject) => {
          $sharedPreferences.get(key, (val) => {
            resolve(val)
          }, (e) => {
            reject(e)
          });
        })
      },
      // 删除
      remove(key) {
        return new Promise((resolve, reject) => {
          $sharedPreferences.remove(key, (val) => {
            resolve(val)
          }, (e) => {
            reject(e)
          });
        })
      },
      // 清除
      claerAll() {
        return new Promise((resolve, reject) => {
          $sharedPreferences.clear(() => {
            resolve()
          }, (e) => {
            reject(e)
          });
        })
      }
    },
    // 打开新的 webpage页面
    openWebPage(opt = {}) {
      opt = {
        showTitleBar: false,
        orientation: 'SCREEN_ORIENTATION_LANDSCAPE',
        url: '',
        title: '',
        ...opt
      }
      return new Promise((resolve, reject) => {
        $OpenWebActivityPlugin?.openWebPage(JSON.stringify(opt))
        resolve()
      })
    },
    netDev: {
      playLive(opt = {}) {
        opt = {
          "ip": "",
          "port": "",
          "userName": "",
          "password": "",
          ...opt,
        }
        return new Promise((resolve, reject) => {
          $netDev.playLive(opt, function (arg) {
            resolve(arg)
          }, function (arg) {
            reject(arg)
          });
        })
      }
    },
    // 打开系统浏览器
    openSystemWeb(opt = {}) {
      opt = {
        url: "",
        ...opt
      }
      if (!opt.url) {
        return new Promise.reject('url 必须传')
      }

      return new Promise((resolve, reject) => {
        if (dsf.client.iOS()) {
          windowApp?.openApp({ "appUrl": "", "appDownUrl": opt.url }, function (res) {
            resolve(res);
          }, function (res) {
            reject(res);
          });
        } else {
          $NativeNavigator?.execute({ method: "openSystemWeb", param: opt }, function (res) {
            resolve(res);
          }, function (res) {
            reject(res);
          });
        }
      })
    }
  }
}


let time;
let backClick;
let backReady = true;
function backbutton() {
  if (!backReady) {
    return
  }
  backReady = false
  setTimeout(function () {
    backReady = true
  }, 100)
  if (time == 0) {
    time = (new Date()).getTime(); // 2s内再次点击就退出
  }
  // 监听返回按钮
  const navs = $('#app')[0].__vue__.$root.navs
  const url = dsf.url.parse(window.location.href).hashKey
  if (url == '/' || url == '/mobile/login' || navs.findIndex(({ href }) => href == url) >= 0) {  // 处于app首页,满足退出app操作
    let tmpTime = (new Date()).getTime()
    let isShort = (tmpTime - time < 4000)
    if (isShort && backClick != 0) {
      navigator.app.exitApp()
      return
    } else if (isShort) {
      backClick++
    } else {
      backClick = 0
    }
    dsf.layer.message("再按一次退出程序")
    time = tmpTime
    return
  } else {
    backClick = 0
    history.back()
  }
}
