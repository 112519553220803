import formControl from "./formControl";
export default {
  mixins: [formControl],
  props: {
    childMetaDataCodes: {
      type: Array,
      default() {
        return [];
      }
    },
    isOneToMany: {
      type: Boolean,
      default: true
    },
    //元数据等级，主表为0子表或者子表组件为1
    metaDataLevel: {
      type: Number,
      default: 1
    },
    metadata: {
      type: Object,
      default() {
        return dsf.metadata.get("sublist-meta-data");
      }
    }
  },
  methods: {
    emitValueChange(v, data) {
      let eventArgs = { row: data || null, list: v };
      formControl.methods.$emitValueChange.call(this, v);
      this.$dispatch("value-mutation", eventArgs);
    }
  },
  design: {
    isMask: false,
    metadata: {
      isDeep: false,
      create(root) {
        if (!this.metadata) {
          this.metadata = {};
        }
        let childMetaData = [];
        let currCellSlot = ""
        dsf.designer.recursionLayoutTree(this, null, null, (child, parent, slot) => {
          if (child != this) {
            if (parent == this) {
              currCellSlot = slot;
            }
            let designOptions = child.$options.design || {};
            let metadataNode = designOptions.metadata || {};
            let result = metadataNode.create && metadataNode.create.call(child, root);
            if (result && result.metadata) {
              result.self = child;
              result.metadata.inColumn = currCellSlot;
              result && childMetaData.push(result);
              let isDeep = dsf.isDef(metadataNode.isDeep) ? metadataNode.isDeep : true;
              if (!isDeep) {
                return false;
              }
            }
          }
        });
        let exportRules = {};
        _.each(this.exportOptions, (v, k) => {
          if (v && v.length > 0) {
            exportRules[this.caption + "." + k] = v;
          }
        });
        return [{
          self: this,
          init: null,
          //可以导出列的配置
          export: {
            ...exportRules
          },
          metadata: this.metadata
        },
        ...childMetaData
        ];
      }
    }
  }
};