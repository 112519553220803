// import client from './client';
import helper from './helper';
export default {
  pc: {},
  mobile: {},
  openWindow() {
    let args = Array.prototype.slice.call(arguments);
    return proxyFunction.apply(this, ['openWindow', ...args]);
  },
  openDialog() {
    let args = Array.prototype.slice.call(arguments);
    return proxyFunction.apply(this, ['openDialog', ...args]);
  },
  closeWindow() {
    let args = Array.prototype.slice.call(arguments);
    return proxyFunction.apply(this, ['closeWindow', ...args]);
  },
  message() {
    let args = Array.prototype.slice.call(arguments);
    return proxyFunction.apply(this, ['message', ...args]);
  },
  loading() {
    let args = Array.prototype.slice.call(arguments);
    return proxyFunction.apply(this, ['loading', ...args]);
  },
  closeLoading() {
    let args = Array.prototype.slice.call(arguments);
    return proxyFunction.apply(this, ['closeLoading', ...args]);
  },
  alert() {
    let args = Array.prototype.slice.call(arguments);
    return proxyFunction.apply(this, ['alert', ...args]);
  },
  confirm: function () {
    let args = Array.prototype.slice.call(arguments);
    return proxyFunction.apply(this, ['confirm', ...args]);
  },
  prompt: function () {
    let args = Array.prototype.slice.call(arguments);
    return proxyFunction.apply(this, ['prompt', ...args]);
  },
  /**
   * 预览图片
   * @param opt {String[] | {images: String[], startPosition: number, onClose: function}}
   */
  imagePreview: function () {
    let args = Array.prototype.slice.call(arguments);
    return proxyFunction.apply(this, ['imagePreview', ...args]);
  },
}

function proxyFunction() {
  let args = Array.prototype.slice.call(arguments);
  let key = dsf.global.$isMobile ? 'mobile' : 'pc'
  if (dsf.isDef(dsf.layer.type)) {
    key = dsf.layer.type;
  }
  let fnName = args[0];
  let _dsf = dsf;
  let win = window;
  let fnArgs = args.slice(1);
  // if (key == 'pc') {
  //   //如果是PC的化尽可能找到当前窗口的顶层窗口
  //   // if(window)
  //   let urlInfo = dsf.url.parse(window.location.href);
  //   let entry_exclude = ['designer.html'];
  //   let topWin = helper.getTopWindow();
  //   if (topWin.__multiPageVm__) {
  //     win = topWin;
  //   }
  //   // let result = _.filter(entry_exclude, (it) => {
  //   //   return urlInfo.pathname.endsWith("designer.html")
  //   // })
  //   // if (result.length <= 0) {
  //   //   win = helper.getTopWindow();
  //   // }
  //   // if (topWin.__multiPageVm__) {
  //   //   topWin.__multiPageVm__.
  //   // }
  // }
  if (key && win.dsf.layer[key][fnName]) {
    return dsf.layer[key][fnName].apply(this, fnArgs);
  }
  return null;
}


