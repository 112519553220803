let echartScriptLoading = false;
let echartScriptLoaded = false;

export default {
  loadEcharts(tag) {
    return new Promise((reslove, reject) => {
      if (!window.echarts && !window.$echarts) {
        /****观察者模式动态加载echarts script 标签，防止多次加载script   start******************/
        dsf.emit.$on('loadCharts', () => {
          window.$echarts = window.echarts;
          //加载完成
          reslove();
        }, tag);
        if (!echartScriptLoading) {
          echartScriptLoading = true;
          // 订阅加载script事件
          dsf.emit.$on('loadScriptCharts', () => { }, window)
          var head = document.getElementsByTagName('head')[0];
          var script = document.createElement('script');
          head.appendChild(script);
          script.src = './static/js/libs/echarts/echarts.min.js';
          script.onload = () => {
            dsf.emit.$emit('loadCharts')// 发布加载echarts 成功消息
            echartScriptLoaded = true;
          }
        }
        /****观察者模式动态加载echarts script 标签，防止多次加载script   end******************/
      } else {
        // this.chartObj = window.$echarts.init(this.$refs["chart"]);
        //加载完成
        reslove();
      }
      // window.addEventListener("reisize", this.$resize.bind(this));
    })
  },
  loadHcharts() {

  }
}