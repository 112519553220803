import propertyDialogSetting from '../../propertyDialogSetting'
export default {
  mixins: [propertyDialogSetting],
  props: {
    // 不配置 url
    noUrl: {
      type: Boolean,
      default: false,
    },
    selectTabsVals: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    selectTabs() {
      let tabs = {};
      this.tabs.map((tab) => (tabs[tab.name] = tab));
      return this.selectTabValues.map((name) => tabs[name]);
    },
  },
  data() {
    return {
      tabs: [],
      selectTabValues: [],
      loadComplete: false,
      options: {
        animation: 300,
        delay: 100,
        forceFallback: true,
        ghostClass: "ghost",
        dragClass: "drag",
        chosenClass: "drag-chosen",
      },
    };
  },
  created() {
    this.tabs = [...(this.currentData || [])];
    this.selectTabValues = [...(this.selectTabsVals || [])];
    this.loadScript(() => {
      this.loadComplete = true;
    });
  },
  methods: {
    yes() {
      return this.selectTabValues;
    },
    reset() {
      this.selectTabValues = this.tabs.map((tab) => tab.name);
    },
    loadScript(callback) {
      if (window.vuedraggable) {
        this.scriptLoaded = true;
        callback?.();
      } else {
        this.$http
          .importFiles([
            dsf.url.getWebPath("$/js/libs/vuedraggable/Sortable-1.13.0.js"),
            dsf.url.getWebPath("$/js/libs/vuedraggable/vuedraggable.umd.js"),
          ])
          .then(() => {
            this.scriptLoaded = true;
            callback?.();
          })
          .catch((err) => {
            console.error(err);
            dsf.layer.message("加载fabric文件报错", false);
          });
      }
    },
    update({ oldIndex, newIndex }) {
      this.swapArray(this.selectTabValues, oldIndex, newIndex);
    },
    swapArray(arr, index1, index2) {
      arr[index1] = arr.splice(index2, 1, arr[index1])[0];
      return arr;
    },
    removeTag(tab, i) {
      this.selectTabValues.splice(i, 1);
    },
  },
  mounted() { },
};