
let apiMap = {};
export default {
  apiMap: apiMap,
  addAPI(key, fn) {
    if (!apiMap[key]) {
      apiMap[key] = fn;
    } else {
      dsf.warn(`api:${key}注册与已有api冲突`);
    }
  },
  getAPI(){
    return apiMap;
  }

}