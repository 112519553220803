import control from "./control";
export default {
  provide() {
    return {
      $list: this,
      $buttonOwner: this
    }
  },
  props: {
    childMetaDataCodes: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  methods: {
    /**设计器模式下可用函数 start */
    $getChildMetadataList() {
      if (this.isDesign) {
        let result = dsf.designer.createComponentMetaData(this, this.$page);
        let metadata = null;
        // let metadata = grid.$options.design.metadata.create.call(grid);
        if (result?.[0]?.schema) {
          let schema = result?.[0]?.schema;
          metadata = _.filter(schema, (it) => it.metadata);
        }
        return metadata;
      }

    },
    async $getMetadataTree(metadataArr) {
      if (this.isDesign) {
        let treeData = null;
        if (!this.$isPrototype) {
          treeData = await this.$getRealTreeData(metadataArr[0].metadata, metadataArr[0].metadata.rm_type || 3);
        } else {
          treeData = this.$getStaticTreeData(metadataArr);
        }
        return treeData;
      }
    },
    async $getRealTreeData(metadata, rm_type) {
      if (this.isDesign) {
        let data = [];
        if (rm_type == "8") {
          let params = {
            rmId: metadata.rmId
          };
          data = await this.$webAPI.getDbSourceFieldsTree(params);
        } else if (rm_type == "3") {
          let params = {
            namespace: metadata.at
          };
          data = await this.$webAPI.getMetaDataFieldsTree(params);
        }
        return data;
      }
    },
    $getStaticTreeData(metadataArr) {
      let arr = dsf.mix(true, [], metadataArr);
      let root = {
        name: "可用字段",
        children: []
      }
      _.each(arr, (it) => {
        let node = { ...it.metadata };
        node.children = [];
        let name = node.name
        let tableName = node.at.replace(/\./g, "_");
        node.id = tableName + "." + node.code;
        node.field = node.code;
        node.fieldCaption = node.name;
        node.metadata = node;
        node.name = name || node.id;
        if (node.valueAttributes.length > 1) {
          _.each(node.valueAttributes, (va) => {
            let n = name ? (name + "(" + va.name + ")") : (tableName + "." + va.code);
            let type =
              node.children.push({
                ...va,
                id: tableName + "." + va.code,
                name: n,
                field: node.code + "_" + va.code,
                fieldCaption: n,
                metadata: node,
              })
          })
        }
        root.children.push(node);
      });
      return [root];
    },
    /**设计器模式下可用函数 end*/

    $getPageList(data, index, size) {
      let s = (index - 1) * size;
      let e = s + size;
      return data.slice(s, e);
    },
    // $getDataFromMatrixData(data) {
    //   if (data && data.length > 0) {
    //     if (dsf.isArray(data[0])) {
    //       _.each(data || [], (row, i) => {
    //         let item = {};
    //         _.each(this.childMetaDataCodes || [], (mkey, i) => {
    //           item[mkey] = this.$getRealStaticValue(row[i]);
    //         });
    //         data.splice(i, 1, item);
    //       })
    //     }
    //   }
    //   return data;
    // }
  },
  design: {
    settingButton: true,
    settingMenus: [
      {
        text: "静态数据维护",
        command: "list-static-data",
        handler: addStaticData,
        hidden() {
          return !this.$isPrototype;
        }
      }
    ]
  }
}
//列表添加静态数据
function addStaticData(item, proxy) {
  let _this = this;
  let m = dsf.designer.createComponentMetaData(this, this.$page);
  let statDataKey = this.$options.design.staticDataPropKey
  let value = this[statDataKey] || [];
  if (value.length <= 0) {
    let obj = {};
    value.push(obj)
  }
  let ov = value;
  value = [];
  let fields = []
  let childArr = m?.[0]?.schema || [];
  let mobj = _.keyBy(childArr, 'key');
  if (this.childMetaDataCodes.length > 0) {
    _.each(this.childMetaDataCodes, (it) => {
      let name = "";
      if (mobj[it]) {
        name = mobj[it]?.metadata?.name
      }
      let fieldName = dsf.metadata.getField(it);
      fields.push({
        name: fieldName,
        displayName: name || fieldName
      })
    });
  }
  if (fields.length == 0 && !this.isFormControl) {
    let cols = this.getLastColumns();
    _.each(cols, (it) => {
      fields.push({
        name: it.columnId,
        displayName: it.label
      });
    })
  }
  for (let i = 0; i < ov.length; i++) {
    let obj = {};
    _.each(fields, (it) => {
      obj[it.name] = null;
    });
    for (let k in ov[i]) {
      let _key = dsf.metadata.getField(k);
      if (_key in obj) {
        obj[_key] = ov[i][k];
      }
    }
    value.push(obj)
  }

  this.$openDialog({
    title: "数据维护",
    content: "DesStaticListData",
    width: "90vw",
    height: "60vh",
    overflow: "hidden hidden",
    params: {
      designer: this.$designer,
      value: {
        data: value,
        fields: fields
      }
    },
    btns: [
      {
        text: "确定",
        handler(res) {
          let data = res?.yes();
          if (_this.caption && data) {
            let _data = [];
            _.each(data || [], (row, i) => {
              let obj = {};
              _.each(fields, (el) => {
                let tokey = el.name;
                let fromKey=el.name;
                let m = _.find(_this.childMetaDataCodes, (f) => {
                  let field = dsf.metadata.getField(f);
                  return field == fromKey;
                });
                if (m) {
                  tokey = m
                }
                obj[tokey] = dsf.isDef(row[fromKey]) ? row[fromKey] + "" : ""
              })
              // _.each(_this.childMetaDataCodes, (key) => {
              //   let field = dsf.metadata.getField(key);
              //   obj[key] = row[field];
              // });
              // console.log(i)
              obj._id = i;
              _data.push(obj);
            })
            _this[statDataKey] = _data;
          }
        }
      },
      {
        text: "清除",
        handler() {
          _this[statDataKey] = null;
        }
      },
      {
        text: "取消"
      }
    ]
  });
}