import dsf from '../utils';
import layoutView from './layoutView';
export default {
  mixins: [layoutView],
  data() {
    return {
      fromPath: ""
    }
  },
  created() {
    try {
      if (!this.$dialog) {
        let index = _.findIndex(dsf.global.$$state.routeChain, v => v.path == this.$route.path);
        let preViewContext = window.$$state.routeChain[index - 1];
        if (preViewContext) {
          let preView = this.$store.getters['keepAlive/instances'][preViewContext.path];
          if (preView) {
            this.$openerVm = preView.vm;
            if (dsf.global.$windowArgs && dsf.global.$windowArgs[this.$route.path]) {
              this.$dialogArgs = dsf?.global?.$windowArgs[this.$route.path];
            }
            else {
              this.$dialogArgs = null;
            }
          }
        }
      } else {
        this.$dialogArgs = this.$dialog.dialogArgs || null;
        this.$openerVm = this.$dialog.openerVm;
      }

    } catch (ex) {
      dsf.error(ex)
    }
  },
  activated() {
    if (this.$isLoaded) {
      //检测当前页是否在刷新队列中,如果在则刷新数据
      this.$popRefresh();
      //还原上次滚动条滚动的位置
      if (this.$el && this.$el.nodeType == 1) {
        const $content = this.$el.querySelectorAll("[scroll-id]");
        let scrolls = this.$route.meta.scrolls;
        _.each($content, (el) => {
          let sid = el.getAttribute("scroll-id");
          let scroll = scrolls[sid];
          if (scroll) {
            el.scroll(scroll.x, scroll.y);
            delete scrolls[sid];
          }
        });
      }
    }
  },
  mounted() {
    this.$popRefresh();
    let index = _.findLastIndex(window.$$state.routeChain, (it, i) => {
      return it.path == this.$route.path;
    })
    let last = null;
    if (index >= 0) {
      last = window.$$state.routeChain[index - 1];
    }
    this.fromPath = last || null;
  },
  methods: {
    $closeWindow() {
      let dialog = this.$dialog;
      if (dialog) {
        dialog.close();
      }
      else if (this.$openerVm) {
        this.$router.back();
      }
    },
    reloadParentViewData() {
      if (dsf.global.$isMobile && dsf.global.$$state) {
        let last = null;
        if (this.fromPath) {
          last = this.fromPath;
        }
        else {
          last = window.$$state.routeChain[window.$$state.routeChain.length - 2];
        }
        if (last && last.path && last.path != "/") {
          this.$store && this.$pushRefresh(last.path)
        }
      }
    },
    $popRefresh() {
      if (this.$refreshQueue[this.$route.path]) {
        try {
          this.$removeRefresh(this.$route.path);
          this.reloadData();
        }
        catch (ex) {
          if (dsf.isError(ex)) {
            dsf.error("刷新" + this.$route.path + "失败")
          }
        }
      }
    }
  },
  // beforeRouteEnter(to, from, next) {
  //   next()
  // },
  //全局混入页面离开的路由操作,离开时记录所有打了scroll-id标记容器的滚动条位置
  beforeRouteLeave(to, from, next) {
    if (this.$el && this.$el.nodeType == 1) {
      const $content = this.$el.querySelectorAll("[scroll-id]");
      let scrolls = {};
      _.each($content, (el) => {
        let sid = el.getAttribute("scroll-id");
        scrolls[sid] = { y: el.scrollTop, x: el.scrollLeft };
      });
      if (from && from.meta) {
        from.meta.scrolls = scrolls;
      }
    }
    next();
  },
  beforeDestroy() {
    //清理掉$openWindow传递的参数
    if (dsf.global.$windowArgs) {
      delete dsf.global.$windowArgs[this.$route.path];
    }
  }
}