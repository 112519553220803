
import layoutView from './layoutView';
export default {
  mixins: [layoutView],
  created() {
    try {
      if(window._spv_){
        let frameElement = window?.frameElement;
        this.$dialogArgs = frameElement?.args ? JSON.parse(frameElement.args) : null;
        this.$openerVm = frameElement?.openerVm;
      }
      // 判断弹出层,优先级 iframe 》dialog 》window
      else if (window?.frameElement?.closest('.el-dialog[role="dialog"]')) {
        //兼容DsfIframe组件 或者DsfIframeDialog组件
        let dialog = window?.frameElement?.$dialog || window?.frameElement?.args?.$dialog
        if (dialog) {
          let args = window?.frameElement?.args || window?.frameElement?.$dialog?.dialogArgs || null;
          if (dsf.type(args) == 'string') {
            args = JSON.parse(args);
          }
          this.$dialogArgs = window.frameElement.args ? JSON.parse(window.frameElement.args) : null;
          this.$openerVm = dialog.openerVm;
        }
      } else if (this.$dialog) {
        this.$dialogArgs = this.$dialog.dialogArgs || null;
        this.$openerVm = this.$dialog.openerVm;
      } else if (window.opener) {
        if (window.opener.dialogArgs && window.opener.dialogArgs[window.name]) {
          this.$dialogArgs = JSON.parse(window.opener.dialogArgs[window.name]) || null;
        }
        if (window.opener.openerVms && window.opener.openerVms[window.name]) {
          this.$openerVm = window.opener.openerVms[window.name] || null;
        }
      }
    } catch (ex) {
      dsf.error(ex)
    }
  },
  methods:{
    $closeWindow() {
      dsf.layer.closeWindow(window,this);
    },
    reloadParentViewData(){
      //pc端获取当前页面的父页面并刷新父页面数据
      if (this.$openerVm) {
        //更新父页面数据
        this.$openerVm && this.$openerVm.reloadData && this.$openerVm.reloadData();
      }
    }
  }
}