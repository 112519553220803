// 数据列表子控件
export default{
  inject: {
    // 数据列表实例
    $list: {
      default: null
    },
    $buttonOwner: {
      default: null
    },
    // 数据字段前缀
    $dataPrefix: {
      default: null
    }
  },
  props: {
    local: {
      type: Object,
      default() {
        return null;
      }
    }
  },
  computed: {
    // 当前checkbox是否被禁用
    disabled() {
      if (this.isDesign || !this.$list || !this.$list.checkboxColumn.show || !this.$list.selectable) {
        return false;
      }
      return this.$list.selectable(this.local, this.local.$index);
    },
    // 当前是否被选中
    isSelected: {
      get() {
        // 没有选择功能
        if (this.isDesign || !this.$list || !this.$list.checkboxColumn.show) {
          return false;
        }
        // 单选
        if (this.$list.isSingleChecked) {
          return this.$list.multipleSelection && this.$list.multipleSelection._id == this.local._id;
        }
        // 多选
        return this.$list.checkedDatas.indexOf(this.local._id) > -1;
      },
      set(isSelected) {
        if (!this.isDesign && this.$list && this.$list.checkboxColumn.show) {
          // 单选
          if (this.$list.isSingleChecked) {
            this.$list.multipleSelection = isSelected ? this.local : null;
          }
          // 多选
          else {
            this.$list.handlecheckedDataChange(this.local, isSelected)
          }
        }
      }
    }
  },
  methods: {
    getVal(key) {
      if (!this.local) return null;
      if (['_id', '$editing', '$hash', '$index', '$order'].indexOf(key) > -1) {
        return this.local[key]
      }
      return this.local[this.$dataPrefix + '.' + key] || this.local[key];
    },
    getRowButtons() {
      return dsf.mix(true, [], this.$list.rowButtons || []);
    },
  }
}