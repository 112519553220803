import requireJS from "./require";
import "./repair";
import helper from "./helper";
import url from "./url";
import http from "./http";
import base64 from "./base64";
import md5 from "./md5";
import date from "./date";
import config from "./config";
import flexible from './flexible';
import component from "./component";
import array from "./array";
import metadata from "./metadata";
import designer from "./designer";
import api from './api';
import client from './client';
import layer from './layer';
import math from './math';
import express from './express';
import init from './init';
import cookies from './cookies';
import validate from './validate';
import cachePool from './cache';
import storage from './storage';
import idCode from './idCode';
import transition from './transition';
import mime from './filemime';
import buttons from './buttons'
import driver from '../driver';
import unitTest from './unitTest';
import custom from './customer'
import enums from './enums';
import emit from './emit'
import ws from './ws';
import ufunc from './customMathematical'
import html from './html';
import safe from './safe';
import file from './file';
import chart from './chart';
import screenFull from './screenFull';
import ui from './ui';
// import aes from './aes';

let global = window || global;

let dsf = {
  ui,
  emit,
  chart,
  version: "5.0",
  init,
  api,
  ...helper,
  url,
  http,
  base64,
  // aes,
  md5,
  flexible,
  date,
  config,
  ...component,
  array,
  global,
  metadata,
  ...requireJS,
  designer,
  client,
  layer,
  math,
  express,
  enums,
  validate,
  cachePool,
  storage,
  idCode,
  mime,
  buttons,
  driver,
  unitTest,
  custom,
  ws,
  ufunc,
  safe,
  file,
  screenFull,
  htmlParse: function (str) {
    return html.parse(str)
  },
  transition: {
    ...transition
  }
};
if (cookies) {
  dsf.mix(dsf, { 'cookies': cookies })
}
if (window.$$func) {
  dsf.mix(dsf, { "$$func": window.$$func });
  delete window.$$func;
}


//如果window对象存在则表示在客户端运行，否则使用node的global对象作为全局对象
if (global) {
  global.$mixins = {};
  global.$platformRouter = {
    "pc": [],
    "mobile": [],
    "designer": []
  };
  global.$i18n = {};
  global.$components = {};
  global.$platformStore = {};
  global.$buttons = {
    "pc": null,
    "mobile": null
  };
  global.dsf = dsf;
  if(dsf.isUnDef(global.$isMobile)){
    Object.defineProperty(global, '$isMobile', (function () {
      let isMobile = false;
      return {
        get() {
          return isMobile
        },
        set(v) {
          isMobile = v;
        }
      }
    })())
  }
 
}

export default dsf;