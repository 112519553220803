export default {
  inject: {
    $choiceParams: {
      default: null
    }
  },
  data() {
    return {
      fromDataChioce: false,
      //用于列表检索时是否重置选中值
      searchResetSelect: true
    };
  },
  created() {
    if (!this.isDesign && this.$vm && this.caption && !this.$vm.$data.$choice) {
      this.$vm.$data.$choice = this;
    }
    // TODO 搜索记住 选择
    // if(this.$vm){
    //   if(this.$vm.$controls[this.$designId]){
    //     let com=this.$vm.$controls[this.$designId];
    //     if('isSearchResetSelect' in com){
    //       com.isSearchResetSelect=false;
    //     }
    //   }
    // }
  },
  methods: {
    // //加载被选中的数据
    // loadSelected() {

    // },
    //返回被选中的数据
    getReturnValue() { }
  }
}