export default {
  ensure: function ensure(target, item, callback) {
    //只有当前数组不存在此元素时只添加它
    if (dsf.isUnDef(callback)) {
      if (target.indexOf(item) === -1) {
        return target.push(item);
      }
    } else {
      let result = _.find(target, (el) => {
        return callback && callback(el);
      });
      if (!result) {
        target.push(item);
      }
    }
  },
  removeAt: function removeAt(target, index) {
    //移除数组中指定位置的元素，返回布尔表示成功与否
    return !!target.splice(index, 1).length;
  },
  removeWith: function removeWith(target, callback) {
    _.eachRight(target, (el, i) => {
      if (callback) {
        let result = callback(el, i);
        if (result) {
          target.splice(i, 1);
        }
      }
    })
  },
  remove: function remove(target, item) {
    //移除数组中第一个匹配传参的那个元素，返回布尔表示成功与否
    if (dsf.type(item) != 'function') {
      var index = target.indexOf(item);
      if (~index) return this.removeAt(target, index);
      return false;
    } else {
      var index = _.findIndex(target, (el, i) => {
        return item(el, i);
      });
      if (~index) return this.removeAt(target, index);
      return false;
    }
  },
  removeAll: function(target) {
    target.splice(0, target.length);
  }
};