export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ["currentData", "config", "owner", "designer", "isMobile"],
  methods: {
    yes() { },
    validate() {
      return true;
    }
  }
};
