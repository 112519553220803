const methodMap = [
  [
    'requestFullscreen',
    'exitFullscreen',
    'fullscreenElement',
    'fullscreenEnabled',
    'fullscreenchange',
    'fullscreenerror',
  ],
  // New WebKit
  [
    'webkitRequestFullscreen',
    'webkitExitFullscreen',
    'webkitFullscreenElement',
    'webkitFullscreenEnabled',
    'webkitfullscreenchange',
    'webkitfullscreenerror',

  ],
  // Old WebKit
  [
    'webkitRequestFullScreen',
    'webkitCancelFullScreen',
    'webkitCurrentFullScreenElement',
    'webkitCancelFullScreen',
    'webkitfullscreenchange',
    'webkitfullscreenerror',

  ],
  [
    'mozRequestFullScreen',
    'mozCancelFullScreen',
    'mozFullScreenElement',
    'mozFullScreenEnabled',
    'mozfullscreenchange',
    'mozfullscreenerror',
  ],
  [
    'msRequestFullscreen',
    'msExitFullscreen',
    'msFullscreenElement',
    'msFullscreenEnabled',
    'MSFullscreenChange',
    'MSFullscreenError',
  ],
];

const nativeAPI = (() => {
  const unprefixedMethods = methodMap[0];
  const returnValue = {};

  for (const methodList of methodMap) {
    const exitFullscreenMethod = methodList?.[1];
    if (exitFullscreenMethod in document) {
      for (const [index, method] of methodList.entries()) {
        returnValue[unprefixedMethods[index]] = method;
      }

      return returnValue;
    }
  }

  return false;
})();

const eventNameMap = {
  change: nativeAPI.fullscreenchange,
  error: nativeAPI.fullscreenerror,
};

/**
 * ###全屏API [查看更多](https://github.com/sindresorhus/screenfull)
 *
 * > Safari 在桌面和 iPad 上受支持
 * > 但在 iPhone 上不受支持。
 * > 这是浏览器中的限制，而不是screenFull中的限制。
 *
 * ####属性一：
 *  `.isFullscreen`
 *  是否全屏
 *
 * ####方法一：
 *  `.request(element = html, options?)`
 *  使元素全屏显示。
 *
 * ####方法二：
 *  `.exit()`
 *  退出全屏
 *
 * ####方法三：
 *  `.toggle(element = html, options?)`
 *  全屏和非全屏自由切换
 *
 * ####方法四：
 *  `.on(event, function)`
 *  监听事件
 *  `event` 可选 'change' | 'error'
 *
 * ####方法五：
 *  `.off(event, function)`
 *  取消事件监听
 *  `event` 可选 'change' | 'error'
 *
 * ####方法六：
 *  `.onchange(function)`
 *  等价于`.on('change', function)`
 *
 * ####方法七：
 *  `.onerror(function)`
 *  等价于`.on('error', function)`
 */
let screenFull = {
  request(element = document.documentElement, options) {
    return new Promise((resolve, reject) => {
      const onFullScreenEntered = () => {
        screenFull.off('change', onFullScreenEntered);
        resolve();
      };

      screenFull.on('change', onFullScreenEntered);

      const returnPromise = element[nativeAPI.requestFullscreen](options);

      if (returnPromise instanceof Promise) {
        returnPromise.then(onFullScreenEntered).catch(reject);
      }
    });
  },
  exit() {
    return new Promise((resolve, reject) => {
      if (!screenFull.isFullscreen) {
        resolve();
        return;
      }

      const onFullScreenExit = () => {
        screenFull.off('change', onFullScreenExit);
        resolve();
      };

      screenFull.on('change', onFullScreenExit);

      const returnPromise = document[nativeAPI.exitFullscreen]();

      if (returnPromise instanceof Promise) {
        returnPromise.then(onFullScreenExit).catch(reject);
      }
    });
  },
  toggle(element, options) {
    return screenFull.isFullscreen ? screenFull.exit() : screenFull.request(element, options);
  },
  onchange(callback) {
    screenFull.on('change', callback);
  },
  onerror(callback) {
    screenFull.on('error', callback);
  },
  on(event, callback) {
    const eventName = eventNameMap[event];
    if (eventName) {
      document.addEventListener(eventName, callback, false);
    }
  },
  off(event, callback) {
    const eventName = eventNameMap[event];
    if (eventName) {
      document.removeEventListener(eventName, callback, false);
    }
  },
  raw: nativeAPI,
};

Object.defineProperties(screenFull, {
  isFullscreen: {
    get: () => Boolean(document[nativeAPI.fullscreenElement]),
  },
  element: {
    enumerable: true,
    get: () => document[nativeAPI.fullscreenElement] ?? undefined,
  },
  isEnabled: {
    enumerable: true,
    // Coerce to boolean in case of old WebKit.
    get: () => Boolean(document[nativeAPI.fullscreenEnabled]),
  },
});

if (!nativeAPI) {
  screenFull = {isEnabled: false};
}

export default screenFull;