const READER_FUNCTION = {
  // 读取文本文件
  text: 'readAsText',
  // 读取文件并将数据作为base64编码的数据url返回。
  base64: 'readAsDataURL',
  // 读取文件并将数据作为二进制数据返回。
  binary: 'readAsBinaryString',
  // 读取文件并将数据作为二进制数据缓冲区返回。
  buffer: 'readAsArrayBuffer'
};

function loadScript(url) {
  return new Promise(function(resolve) {
    dsf.http
      .importFiles(url)
      .then(resolve)
      .catch(err => {
        dsf.error(err);
        dsf.layer.message("加载fabric文件报错", false);
      });
  })
}

export default {
  /**
   * fileReader第二个参数
   */
  READER_TYPE: {
    // 读取文本文件
    text: 'text',
    // 读取文件并将数据作为base64编码的数据url返回。
    base64: 'base64',
    // 读取文件并将数据作为二进制数据返回。
    binary: 'binary',
    // 读取文件并将数据作为二进制数据缓冲区返回。
    buffer: 'buffer'
  },
  /**
   * 选择文件
   * @param accept    文件类型/文件后缀
   * @param multiple  是否多选
   * @returns {Promise<File | File[] | null>}
   */
  load: function (accept = "*", multiple = false) {
    return new Promise(function (resolve, reject) {
      let input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', accept);
      multiple && input.setAttribute('multiple', true);
      input.onchange = function () {
        let files = input.files;
        if (multiple) {
          resolve(files);
        } else if (files.length) {
          resolve(files[0]);
        } else {
          resolve(null);
        }
      }
      input.onerror = function (e) {
        reject(e);
      }
      input.click();
    });
  },
  /**
   * 读取文件数据，但最后具体内容自行解析
   * @param file 要读取的文件
   * @param type READER_TYPE的值
   * @param encoding 标识type为text时解析文本的编码
   * @returns {Promise<any>}
   */
  fileReader: function(file, type = "text", encoding = "UTF-8") {
    let READER_TYPE = this.READER_TYPE;
    return new Promise(function (resolve, reject) {
      let reader = new FileReader();
      reader.onload = function() {
        resolve(reader.result);
      };
      reader.onerror = reject;
      if (type === READER_TYPE.text) {
        reader[READER_FUNCTION.text](file, encoding);
      } else {
        reader[READER_FUNCTION[type]](file);
      }
    });
  },
  /**
   * 解析excel文件类容
   * （常规小文件）
   * @returns {Promise<Object>}
   */
  readXlsx: async function (accept = ".xlsx,.xls,.csv") {
    if (!window.XLSX) {
      await loadScript(dsf.url.getWebPath("$/js/libs/js-xlsx/xlsx.core.min.js"));
    }
    let file = await this.load(accept);
    let result = await this.fileReader(file, this.READER_TYPE.binary);
    return window.XLSX.read(result, {type:'binary'});
  },
  /**
   * 解析文本文件内容
   * @param accept
   * @returns {Promise<String>}
   */
  readTxt: async function (accept = ".txt") {
    let file = await this.load(accept);
    return await this.fileReader(file);
  },
  /**
   * 解析json文件内容
   * @param accept
   * @returns {Promise<Object>}
   */
  readJSON: async function (accept = ".json") {
    let file = await this.load(accept);
    let result= await this.fileReader(file);
    return JSON.parse(result);
  }
};