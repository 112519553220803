const pcButtons = [];
const mobileButtons = [];

let buttonMap = {
  "pc": null,
  "mobile": null
}

function addButton(options, client = 'pc') {
  if (!options) {
    return;
  }
  let handler = options.handler;
  let moduleName = options.moduleName;
  let groupName = options.groupName;
  if (!moduleName) {
    dsf.warn("按钮未提供有效的所在模块名称");
    return;
  }
  if (!groupName) {
    dsf.warn("按钮未提供有效的所在组名称");
    return;
  }
  if (!handler) {
    dsf.warn("按钮未提供有效的方法");
    return;
  }
  if (!options.actionName) {
    dsf.warn("按钮未提供有效的actionName");
    return;
  }
  delete options.handler;
  delete options.moduleName;
  delete options.groupName;
  let list = client == 'pc' ? pcButtons : mobileButtons;
  list.push({
    actionName: options.actionName,
    button: options,
    handler: handler,
    moduleName: moduleName,
    groupName: groupName
  })
}

function getPcButtons() {
  return pcButtons
}
function getMobileButtons() {
  return mobileButtons;
}

function getButtonHander(actionName, client = 'pc') {
  if (!buttonMap[client]) {
    buttonMap[client] = _.keyBy(client == 'pc' ? pcButtons : mobileButtons, "actionName");
  }
  if (buttonMap[client] && buttonMap[client][actionName]) {
    return buttonMap[client][actionName].handler || dsf.noop;
  }
  return null;
}

export default {
  addButton,
  getPcButtons,
  getMobileButtons,
  getButtonHander
}