import helper from './helper'
let views = [];
let viewsMap = {};



let RouterViews = {
  addView: function (name, route) {
    let v = viewsMap[name]
    if (!v) {
      let view = new DsfRouteView(name, route);
      views.push(view);
      viewsMap[name] = view;
    }
    else {
      dsf.warn("路由视图name属性重复")
    }
  },
  removeView: function (name) {
    dsf.array.removeWith(views, (it) => {
      return it.name == name;
    })
    delete viewsMap[name];
  },
  pushView: function (name, route) {
    let v = viewsMap[name]
    if (v) {
      v.push(route);
    }
    else {
      this.addView(name, route);
    }
  },
  replace: function (name, route) {
    let v = viewsMap[name]
    if (v) {
      v.replace(route);
    }
    else {
      this.addView(name, route);
    }
  },
  views: viewsMap
}

function DsfRouteView(name, route) {
  var initRoute = route;
  this.name = name;
  Object.defineProperty(this, "initRoute", {
    get() {
      return initRoute
    },
    set(v) {
      initRoute = v;
    }
  })
  // this.route = null;
  this.go = function (n) {
    if (this?.router?.history) {
      this.router.history.go(n);
    }
  }
  this.back = function () {
    if (this?.router?.history) {
      this.router.history.go(-1);
    }
    else {
      this.initRoute = route;
    }
  }
  this.forward = function () {
    if (this?.router?.history) {
      this.router.history.go(1);
    }
    else {
      this.initRoute = route;
    }
  }
  this.push = function (route, onComplete, onAbort) {
    if (this?.router?.history) {
      this.router.history.push(route, onComplete, onAbort);
    }
    else {
      this.initRoute = route;
    }
  }
  this.replace = function (route, onComplete, onAbort) {
    if (this?.router?.history) {
      this.router.history.replace(route, onComplete, onAbort);
    }
    else {
      this.initRoute = route;
    }
  }
}
function install(Vue) {

  Vue.mixin({
    beforeCreate() {
      if (this == this.$root) {
        if (!this.$root._dsfRouters) {
          Vue.util.defineReactive(this.$root, '_dsfRouters', RouterViews)
        }
      }
      Object.defineProperty(this, "$dsfRouters", {
        get() {
          return this.$root._dsfRouters
        }
      })
      window.$dsfRouters = this.$root.$dsfRouters
    }
  });
  //多页签混入路由方法
  if (dsf.isMultiPage()) {
    const registerInstance = (vm, callVal) => {
      let i = vm.$options._parentVnode
      if (dsf.isDef(i) && dsf.isDef(i = i.data) && dsf.isDef(i = i.registerRouteInstance)) {
        i(vm, callVal)
      }
    }
    //查找vue注入的组件中vue-router
    const VueRouter = _.find(Vue._installedPlugins, (it) => {
      return dsf.isDef(it.NavigationFailureType) && dsf.isDef(it.isNavigationFailure);
    })
    Vue.mixin({
      beforeCreate() {
        if (dsf.isDef(this.$options.multiView)) {
          this.multiViewInstalled = true;
          this._routerRoot = this;
          this._router = new VueRouter(this.$root.$options.router.options);
          const history = this._router.history
          this._router = dsf.mix(true, this._router, this.$root.$options.router);
          this._router.history = history;
          if (this._router.multiInit) {
            this._router.multiInit(this);
            Vue.util.defineReactive(this, '_route', this._router.history.current)
          }
        }
        else {
          this._routerRoot = (this.$parent && this.$parent._routerRoot) || this;
        }
        registerInstance(this, this)
      },
      destroyed() {
        registerInstance(this)
      }
    })
  }
}

export default install;
